import React, { Component } from "react";
import { getCookie, getBoot, eraseCookie } from "../../utils";
import Swiper from "react-id-swiper";

import swal from "sweetalert";
import "swiper/css/swiper.css";
import "./efectivo1.scss";

export default class Efectivo extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    minAmount: this.props.data.Payretailers.minAmount || 0,
    maxAmount: this.props.data.Payretailers.maxAmount || 0,
    cashList: this.props.data.Payretailers.cashList || [],
  };

  componentDidMount() {
    console.log(this.props.data);
  }

  makeDeposit = (e, id, meth) => {
    e.preventDefault();
    const that = this;
    let moneda = document.getElementById(id + "Currency").value;
    let proveedor =
      meth === "CDC" ? document.getElementById(id + "_proveedor").value : "";
    let monto = document.getElementById(id + "Amount").value;
    let tp_account = document.getElementById("numeroCuenta").value;
    // let nombre = this.state.name;
    let email = getCookie("email");
    swal({
      title: this.state.boot.swal_warning_title,
      text:
        this.state.boot.swal_warning_text6 +
        monto +
        " " +
        moneda +
        this.state.boot.swal_warning_text7 +
        tp_account,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        let req_data = {
          tp_id: tp_account,
          meth: meth,
          data: {
            amount: monto,
            currency: moneda,
            selectedMeth: id,
            psp: proveedor,
            comment: "clientzone",
          },
        };
        fetch("https://www.orotrader.com/paysys/api/processTrx.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(req_data),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.result === 1) {
              swal({
                title: res.data.title,
                text: res.data.content,
                icon: res.data.type,
              });
              let data = {};
              data["email"] = email;
              data["action"] = "sendDepositMail";
              /*
                Los dejo por si se nececitan en algun momento
                data["subject"] = "Confirm Deposit";
                data["tp"] = tp_account;
                data["amount"] = monto;
                data["name"] = nombre;
                */
              fetch("https://www.orotrader.com/api/sendEmail.php", {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((res) => res.json())
                .then((response) => {});
              if (res.result === 1) {
                window.open(res.data.url);
              }
            } else {
              if (res.error === "EISN") {
                eraseCookie();
              } else {
                swal({
                  icon: "error",
                  title: "Oops!",
                  text: that.state.boot[res.error] || res.data,
                });
              }
            }
          });
      }
    });
  };

  render() {
    const params = {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        500: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1000: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      },
    };

    const minAmountUser = this.props.minAmountUser;
    const validateMinAmount = this.state.minAmount > minAmountUser;
    const minAmountToUse = validateMinAmount ? this.state.minAmount : minAmountUser;

    return (
      <Swiper {...params}>
        {this.state.cashList.map((metodo) => (
          <div key={metodo.paymentMethodId} className="opcionEfectivo">
            <img src={metodo.imageUrl} alt={metodo.name} />
            <p>{metodo.channel}</p>
            <form
              onSubmit={(e) => this.makeDeposit(e, metodo.paymentMethodId, "APM")}
            >
              <label htmlFor={metodo.paymentMethodId}>
                {this.state.boot.resumen.moneda}
              </label>
              <br />
              <input
                type="text"
                id={metodo.paymentMethodId + "Currency"}
                value="USD"
                readOnly
                className="monedaDeposito"
              />
              <br />
              <label htmlFor={metodo.name}>
                {this.state.boot.depositar.texto6_2}
              </label>
              <br />
              <input
                type="number"
                id={metodo.paymentMethodId + "Amount"}
                placeholder={`min: ${minAmountToUse}`}
                min={minAmountToUse}
                max={this.state.maxAmount}
                step="0.01"
                required
              />
              <br />
              <button className="boton botonAmarillo" type="submit">
                {this.state.boot.depositar.texto6_3}
              </button>
            </form>
          </div>
        ))}
      </Swiper>
    );
  }
}
