import React, { Component } from "react";
import Menu from "../../../components/menu/menu.jsx";
import { Link } from "react-router-dom";
import { getCookie, getBoot, getApiBase } from "../../../utils";
import Loading from "../../../assets/images/Loading.gif";
import IconoPlataforma from "../../../assets/images/Iconos/Plataforma.png";
import IconoDepositar from "../../../assets/images/Iconos/Depositar.png";
import "./resumen.scss";

export default class Resumen extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    data: {
      summary: {},
      tp_accounts: [],
      tp_accounts_d: [],
    },
    loading: true,
  };
  componentDidMount() {
    this.getResume();
  }
  getResume() {
    let data = {
      session_id: getCookie("session_id"),
    };
    // let aux = {
    //   currency: "USD",
    //   enable: "Activa",
    //   equity: 16,
    //   login: 1300827877,
    //   margin: 0,
    //   margin_free: 16,
    //   platform: "Metatrader",
    // };
    let param = "/get/CustomerData?selection=summary,tp_accounts,tp_accounts_d";
    fetch(getApiBase() + param, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({ data: response.data, loading: false });
      });
  }

  renderTabla = (data) => {
    let tabla = [];
    if (data.length !== 0) {
      data.forEach((cuenta) => {
        tabla.push(
          <div key={cuenta.data.login} className="tablaResumen">
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.cuenta}</span>
              <p>{cuenta.data.login}</p>
            </div>
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.moneda}</span>
              <p>{cuenta.data.currency}</p>
            </div>
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.margen}</span>
              <p>{cuenta.data.margin}</p>
            </div>
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.margen_libre}</span>
              <p>{cuenta.data.margin_free}</p>
            </div>
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.balance_total}</span>
              <p>{cuenta.data.equity}</p>
            </div>
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.estado}</span>
              <p>{cuenta.data.enable}</p>
            </div>
            <div className="celdaResumen">
              <span>{this.state.boot.resumen.plataforma}</span>
              <p>{cuenta.data.platform}</p>
            </div>
            <div className="celdaResumen celdaIconos">
              <span>{this.state.boot.resumen.acciones}</span>
              <Link to="/plataforma">
                <img src={IconoPlataforma} alt="Plataforma" />
              </Link>
              <Link to="/depositar">
                <img src={IconoDepositar} alt="Depositar" />
              </Link>
            </div>
          </div>
        );
      });
    } else {
      tabla.push(
        <div className="tablaResumen">
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.cuenta}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.moneda}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.margen}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.margen_libre}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.balance_total}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.estado}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.plataforma}</span>
            <p>-</p>
          </div>
          <div className="celdaResumen celdaIconos">
            <span>{this.state.boot.resumen.acciones}</span>
            <p>-</p>
          </div>
        </div>
      );
    }
    return tabla;
  };

  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="0" />
            <div className="principal">
              {!this.state.loading && (
                <div className="contenedorResumen">
                  <h1>{this.state.boot.resumen.titulo}</h1>
                  <div className="cajas">
                    <div className="cajaResumen">
                      <span>{this.state.boot.resumen.depositos_totales}</span>
                      <p>$ {this.state.data.summary.total_deposits}</p>
                    </div>
                    <div className="cajaResumen">
                      <span>{this.state.boot.resumen.creditos_otorgados}</span>
                      <p>$ {this.state.data.summary.total_credits}</p>
                    </div>
                    <div className="cajaResumen">
                      <span>{this.state.boot.resumen.retiros_totales}</span>
                      <p>$ {this.state.data.summary.total_withdrawal}</p>
                    </div>
                    <div className="cajaResumen">
                      <span>
                        {this.state.boot.resumen.operaciones_abiertas}
                      </span>
                      <p> {this.state.data.summary.total_positions}</p>
                    </div>
                    <button className="boton">
                      <Link to="/depositar">
                        {this.state.boot.resumen.insertar_fondo}
                      </Link>
                    </button>
                  </div>
                  <h1>{this.state.boot.resumen.cuentas_activas}</h1>

                  {this.renderTabla(this.state.data.tp_accounts)}

                  <h1>{this.state.boot.resumen.cuentas_demo}</h1>
                  {this.renderTabla(this.state.data.tp_accounts_d)}
                </div>
              )}
              {this.state.loading && (
                <img className="loading" alt="Loading" src={Loading} />
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}
