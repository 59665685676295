import React, { Component } from "react";
import {
  getCookie,
  setCookie,
  getApiBase,
  getUrlParms,
  eraseCookie,
} from "./utils";
import Header from "./components/header/header.jsx";
import Footer from "./components/footer/footer.jsx";
import Resumen from "./pages/cuenta/resumen/resumen.jsx";
import Actividad from "./pages/cuenta/actividad/actividad.jsx";
import Detalles from "./pages/perfil/detalles/detalles.jsx";
import Documentos from "./pages/perfil/documentos/documentos.jsx";
import Contrasena from "./pages/perfil/contrasena/contrasena.jsx";
import Depositar from "./pages/transferencia/depositar/depositar.jsx";
import Extraer from "./pages/transferencia/extraer/extraer.jsx";
import Plataforma from "./pages/plataforma/web/plataforma.jsx";
import Transacciones from "./pages/soporte/transacciones/transacciones.jsx";
import Tickets from "./pages/soporte/tickets/tickets.jsx";
import Contacto from "./pages/soporte/contactanos/contactanos.jsx";
// import Club from "./pages/club/invitar/referidos.jsx";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default class App extends Component {
  render() {
    if (getCookie("session_id") === null) {
      let token = getUrlParms("token");
      fetch(getApiBase() + "/check/Login?token=" + token, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.result === 1) {
            setCookie("session_id", result.data.session_id, 20);
            setCookie("name", result.data.name, 20);
            setCookie("lastname", result.data.lastname, 20);
            if (result.data.profile_image !== "") {
              setCookie("imagenPerfil", result.data.profile_image, 20);
            }
            setCookie("email", result.data.email, 20);
            setCookie("minAmount", result.data.minAmount, 20);
            setCookie("referido", result.data.referred, 20);
            setCookie(
              "tp_accounts",
              JSON.stringify(result.data.tp_accounts),
              20
            );
            setCookie("OroClientlang", "es", 20);
            setCookie("country", result.data.country, 20);
            window.location.href = "./";
          } else {
            eraseCookie();
          }
        });
    } else {
      let token = getUrlParms("token");
      if (token !== null && token !== undefined && token !== "") {
        fetch(getApiBase() + "/check/Login?token=" + token, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.result === 1) {
              setCookie("session_id", result.data.session_id, 20);
              setCookie("name", result.data.name, 20);
              setCookie("lastname", result.data.lastname, 20);
              if (result.data.profile_image !== "") {
                setCookie("imagenPerfil", result.data.profile_image, 20);
              }
              setCookie("email", result.data.email, 20);
              setCookie("referido", result.data.referred, 20);
              setCookie(
                "tp_accounts",
                JSON.stringify(result.data.tp_accounts),
                20
              );
              setCookie("OroClientlang", "es", 20);
              setCookie("country", result.data.country, 20);
              window.location.href = "./";
            } else {
              eraseCookie();
            }
          });
      }
    }
    return (
      <Router>
        <div className="App">
          <Header />
          <Switch>
            <Route path="/" exact component={Resumen} />
            <Route path="/actividad" exact component={Actividad} />
            <Route path="/detalles" exact component={Detalles} />
            <Route path="/documentos" exact component={Documentos} />
            <Route path="/contrasena" exact component={Contrasena} />
            <Route path="/depositar" exact component={Depositar} />
            <Route path="/extraer" exact component={Extraer} />
            <Route path="/plataforma" exact component={Plataforma} />
            <Route path="/transacciones" exact component={Transacciones} />
            <Route path="/tickets" exact component={Tickets} />
            <Route path="/contacto" exact component={Contacto} />
            {/* <Route path="/referidos" exact component={Club} /> */}
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}
