import React, { Component } from "react";
import swal from "sweetalert";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ImageUploader from "react-images-upload";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getCookie,
  getBoot,
  getApiBase,
  eraseCookie,
  setCookie,
  getCountries,
} from "../../../utils";
import Menu from "../../../components/menu/menu.jsx";
import IconoUsuario from "../../../assets/images/Iconos/Usuario.png";
import Loading from "../../../assets/images/Loading.gif";
import IconoEditar from "../../../assets/images/Iconos/Editar.png";
import "./detalles.scss";

export default class Detalles extends Component {
  constructor() {
    super();
    this.state = {
      boot: getBoot()[getCookie("OroClientlang")],
      loading: true,
      modalImagen: false,
      pictures: "",
      perfilImage: IconoUsuario,
      customer: {
        last_name: "",
        first_name: "",
        country: "",
        email: "",
        state: "",
        city: "",
        zip_code: "",
        address: "",
        full_number: "",
        birth_date: new Date(),
      },
    };
  }

  componentDidMount() {
    this.getInfoByClient();
  }
  //Funcion para recuperar información del cliente
  getInfoByClient() {
    let data = {
      session_id: getCookie("session_id"),
    };
    fetch(getApiBase() + "/get/CustomerData?selection=details", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((respuesta) => {
        if (respuesta.result === 1) {
          // console.log(respuesta.data);
          this.setState({
            customer: respuesta.data.details,
            loading: false,
            perfilImage: respuesta.data.details.profile_image,
          });
        } else {
          if (respuesta.error === "EISN") {
            eraseCookie();
          } else {
            swal({
              icon: "error",
              title: "Oops!",
              text: this.state.boot[respuesta.error],
            });
          }
        }
      });
  }
  //Funciones para subir imagen
  onDrop = (picture) => {
    this.setState({
      pictures: picture[0],
    });
  };
  uploadImg = () => {
    let formDat = new FormData();
    formDat.append("file", this.state.pictures);
    formDat.append("tipo", 1);
    formDat.append("sub_tipo", 1);
    formDat.append("session_id", getCookie("session_id"));
    fetch(getApiBase() + "/upload/Files", {
      method: "POST",
      body: formDat,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          swal({
            icon: "success",
            title: this.state.boot.swal_success_title,
            text: this.state.boot.swal_success_text1,
          });
          let new_img = data.data.url;
          setCookie("imagenPerfil", new_img, 20);
          document.getElementById("imagenPerfil").src = new_img;
          this.setState({ perfilImage: new_img });
          this.setState({ modalImagen: false });
        } else {
          swal({
            icon: "error",
            title: "Oops!",
            text: this.state.boot[data.error],
          });
        }
      })
      .catch(() =>
        swal({
          icon: "error",
          title: "Oops!",
          text: "Size too hight",
        })
      );
  };
  updateDate = (newDate) => {
    let aux = this.state.customer;
    aux.birth_date = newDate;
    this.setState({ customer: aux });
  };
  updateDetails = (e) => {
    e.preventDefault();
    let name = document.getElementById("name").value;
    let apellido = document.getElementById("lastname").value;
    let data = {
      last_name: document.getElementById("lastname").value,
      first_name: document.getElementById("name").value,
      state: document.getElementById("estado").value,
      city: document.getElementById("ciudad").value,
      zip_code: document.getElementById("cp").value,
      address: document.getElementById("direccion").value,
      birth_date: document.getElementById("fecha").value,
      session_id: getCookie("session_id"),
    };
    fetch(getApiBase() + "/update/Details", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result === 1) {
          swal({
            icon: "success",
            title: this.state.boot.swal_success_title,
            text: this.state.boot.swal_success_text2,
          });
          setCookie("name", name);
          setCookie("lastname", apellido);
          document.getElementById("navUserName").innerHTML =
            name + " " + apellido;
        } else {
          if (response.error === "EISN") {
            eraseCookie();
          } else {
            swal({
              icon: "error",
              title: "Oops!",
              text: this.state.boot[response.error],
            });
          }
        }
      })
      .catch(console.log);
  };

  render() {
    let initial = new Date(this.state.customer.birth_date);
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="3" />
            <div className="principal">
              {!this.state.loading && (
                <>
                  <h1>{this.state.boot.perfil.titulo}</h1>
                  <div className="contenedorDetalles">
                    <form onSubmit={(e) => this.updateDetails(e)}>
                      <div className="detalleUnico">
                        <label htmlFor="name">
                          {this.state.boot.perfil.nombre}
                        </label>
                        <input
                          id="name"
                          type="text"
                          defaultValue={this.state.customer.first_name}
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="lastname">
                          {this.state.boot.perfil.apellidos}
                        </label>
                        <input
                          id="lastname"
                          type="text"
                          defaultValue={this.state.customer.last_name}
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="correo">
                          {this.state.boot.perfil.correo}
                        </label>
                        <input
                          id="correo"
                          type="text"
                          value={this.state.customer.email}
                          disabled
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="pais">
                          {this.state.boot.perfil.pais}
                        </label>
                        <input
                          id="pais"
                          type="text"
                          value={getCountries()[this.state.customer.country]}
                          disabled
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="estado">
                          {this.state.boot.perfil.estado}
                        </label>
                        <input
                          id="estado"
                          type="text"
                          defaultValue={this.state.customer.state}
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="ciudad">
                          {this.state.boot.perfil.ciudad}
                        </label>
                        <input
                          id="ciudad"
                          type="text"
                          defaultValue={this.state.customer.city}
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="cp">{this.state.boot.perfil.cp}</label>
                        <input
                          id="cp"
                          type="text"
                          defaultValue={this.state.customer.zip_code}
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="direccion">
                          {this.state.boot.perfil.direccion}
                        </label>
                        <input
                          id="direccion"
                          type="text"
                          defaultValue={this.state.customer.address}
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="telefono">
                          {this.state.boot.perfil.telefono}
                        </label>
                        <input
                          id="telefono"
                          type="tel"
                          value={this.state.customer.full_number}
                          disabled
                        />
                      </div>
                      <div className="detalleUnico">
                        <label htmlFor="fecha">
                          {this.state.boot.perfil.nacimiento}
                        </label>
                        <DatePicker
                          id="fecha"
                          selected={initial}
                          dateFormat="yyyy-MM-dd"
                          onChange={(date) => this.updateDate(date)}
                        />
                      </div>
                      <div className="detalleUnico">
                        <button
                          className="boton botonAmarillo botonGuardar"
                          type="submit"
                        >
                          {this.state.boot.perfil.guardar}
                        </button>
                      </div>
                    </form>
                    <div className="imagenPerfil">
                      <div className="contenedorImagenPerfil">
                        <button
                          className="boton"
                          onClick={(e) => this.setState({ modalImagen: true })}
                        >
                          <img src={IconoEditar} alt="Editar" />
                        </button>
                        <img src={this.state.perfilImage} alt="Usuario" />
                        <span>{this.state.boot.perfil.imagen}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.state.loading && (
                <img className="loading" alt="Loading" src={Loading} />
              )}
            </div>
          </section>
        </div>
        <Modal isOpen={this.state.modalImagen}>
          <ModalHeader>Subir imagen</ModalHeader>
          <ModalBody>
            <ImageUploader
              withPreview={true}
              label={this.state.boot.upload_img_extensions}
              buttonText={this.state.boot.perfil_subida}
              withIcon={true}
              singleImage={true}
              onChange={this.onDrop}
              imgExtension={[".jpg", ".png", ".gif"]}
              maxFileSize={5242880}
            />
            <div className="contenedorBotonesModal">
              <button
                className="boton botonAmarillo"
                onClick={(e) => {
                  this.setState({ modalImagen: false });
                }}
              >
                {this.state.boot.perfil.cancelar}
              </button>
              <button
                className="boton botonAmarillo"
                onClick={(e) => this.uploadImg()}
              >
                {this.state.boot.perfil.guardar}
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
