import React, { Component } from "react";
// import { Link } from "react-router-dom";
import swal from "sweetalert";
import { getCookie, getBoot, getApiBase, eraseCookie } from "../../../utils";
import Menu from "../../../components/menu/menu.jsx";
import LogoSirix from "../../../assets/images/sirix.png";
import Metatrader from "../../../assets/images/metatrader.png";
import AnyDesk from "../../../assets/images/anydesk.png";
import "./plataforma.scss";

export default class Plataforma extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
  };
  renderTps = () => {
    let tps = JSON.parse(getCookie("tp_accounts"));
    let cuenta = [];
    tps.forEach((element) => {
      let sel = element.tp_id === this.state.selected_tp ? "true" : "false";
      cuenta.push(
        <option selected={sel} value={element.tp_id}>
          {element.tp_id}
        </option>
      );
    });
    return cuenta;
  };
  sendToSirix() {
    let data = { session_id: getCookie("session_id") };
    let selectedTp = document.getElementById("numeroCuenta").value;
    fetch(getApiBase() + "/get/LoginSirix?tp_id=" + selectedTp, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result === 1) {
          window.open(response.data.autologin_url);
        } else {
          if (response.error === "EISN") {
            eraseCookie();
          } else {
            swal({
              icon: "error",
              title: "Oops!",
              text: response.error,
            });
          }
        }
      });
  }
  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="8" />
            <div className="principal">
              <h1>{this.state.boot.plataforma.titulo}</h1>
              <div className="descargaSirix">
                <div className="sirixPC">
                  <img src={LogoSirix} alt="Sirix" />
                  <span>{this.state.boot.plataforma.texto1}</span>
                </div>
                <div className="sirixMovil">
                  {/* <span>{this.state.boot.plataforma.texto4}</span> */}
                </div>
                <div className="sirixPlataforma">
                  <div className="logoPlataforma">
                    <p>{this.state.boot.plataforma.texto2_1}</p>
                  </div>
                  <div className="tituloPlataforma">
                    <select name="numeroCuenta" id="numeroCuenta">
                      {this.renderTps()}
                    </select>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        this.sendToSirix();
                      }}
                    >
                      {this.state.boot.plataforma.texto2_2}
                    </button>
                  </div>
                </div>
                {/* <div className="sirixPlataforma">
                  <div className="logoPlataforma">
                    <img src={LogoSirix} alt="Sirix" />
                  </div>
                  <div className="tituloPlataforma">
                    <p>{this.state.boot.plataforma.texto4_1}</p>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.twomercados.mobile&hl=es"
                        );
                      }}
                    > 
                      {this.state.boot.plataforma.texto3_2}
                    </button>
                  </div>
                </div> */}
                {/* <div className="sirixPlataforma sirixiOS">
                  <div className="logoPlataforma">
                    <img src={LogoSirix} alt="Sirix" />
                  </div>
                  <div className="tituloPlataforma">
                    <p>{this.state.boot.plataforma.texto4_2}</p>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.twomercados.mobile&hl=es"
                        );
                      }}
                    >
                      {this.state.boot.plataforma.texto3_2}
                    </button>
                  </div>
                </div> */}

                {/* <div className="sirixTel"></div> */}
              </div>
              <div className="descargaSirix">
                <div className="sirixPC">
                  <span>{this.state.boot.plataforma.texto5}</span>
                </div>
                <div className="sirixMovil"></div>
                <div className="sirixPlataforma">
                  <div className="logoPlataforma">
                    <img src={AnyDesk} alt="AnyDesk" />
                  </div>
                  <div className="tituloPlataforma">
                    <p>{this.state.boot.plataforma.texto5_1}</p>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        window.open(
                          "https://download.anydesk.com/AnyDesk.exe?_ga=2.222050718.765936823.1587755083-1051596046.1587755083"
                        );
                      }}
                    >
                      {this.state.boot.plataforma.texto3_2}
                    </button>
                  </div>
                </div>
                <div className="sirixPlataforma">
                  <div className="logoPlataforma">
                    <img src={AnyDesk} alt="AnyDesk" />
                  </div>
                  <div className="tituloPlataforma">
                    <p>{this.state.boot.plataforma.texto5_2}</p>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.anydesk.anydeskandroid"
                        );
                      }}
                    >
                      {this.state.boot.plataforma.texto3_2}
                    </button>
                  </div>
                </div>{" "}
                <div className="sirixPlataforma">
                  <div className="logoPlataforma">
                    <img src={AnyDesk} alt="AnyDesk" />
                  </div>
                  <div className="tituloPlataforma">
                    <p>{this.state.boot.plataforma.texto5_3}</p>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        window.open(
                          "https://itunes.apple.com/us/app/anydesk/id1176131273"
                        );
                      }}
                    >
                      {this.state.boot.plataforma.texto3_2}
                    </button>
                  </div>
                </div>
                <div className="sirixPlataforma">
                  <div className="logoPlataforma">
                    <img src={AnyDesk} alt="AnyDesk" />
                  </div>
                  <div className="tituloPlataforma">
                    <p>{this.state.boot.plataforma.texto5_5}</p>
                  </div>
                  <div className="botonPlataforma">
                    <button
                      className="boton botonAmarillo"
                      onClick={(e) => {
                        window.open(
                          "https://download.anydesk.com/anydesk.dmg?_ga=2.9794099.765936823.1587755083-1051596046.1587755083"
                        );
                      }}
                    >
                      {this.state.boot.plataforma.texto3_2}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
