import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCookie, getBoot, eraseCookie } from "../../utils";
import "./menu.scss";
import swal from "sweetalert";
import IconoMenu from "../../assets/images/Iconos/menu_responsive.svg";
import IconoCerrar from "../../assets/images/Iconos/flecha.svg";
import IconoUsuario from "../../assets/images/Iconos/Usuario.png";

export default class Header extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    nivel: "1",
    user: "Usuario",
    imagenPerfil: IconoUsuario,
  };
  componentDidMount() {
    this.actualPage();
    this.setState({ user: getCookie("name") + " " + getCookie("lastname") });
    if (
      getCookie("imagenPerfil") !== null &&
      getCookie("imagenPerfil") !== ""
    ) {
      this.setState({ imagenPerfil: getCookie("imagenPerfil") });
    }
  }
  showBlock = (id) => {
    let bloque = document.getElementById("bloque" + id);
    if (bloque.style.display === "none" || bloque.style.display === "") {
      bloque.style.display = "block";
    } else {
      bloque.style.display = "none";
    }
  };
  actualPage = () => {
    let x = document.querySelectorAll(".enlaces a");
    let y = x[this.props.page];
    y.classList.toggle("paginaActiva");
  };
  showMenu = () => {
    let menu = document.getElementById("menuLateral");
    menu.classList.toggle("menuAbierto");
  };

  logoutConfirm() {
    swal({
      title: this.state.boot.swal_warning_title,
      text: this.state.boot.swal_warning_text3,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        eraseCookie();
      }
    });
  }

  render() {
    return (
      <>
        <div className="menuLateral" id="menuLateral">
          <button onClick={(e) => this.showMenu()} className="cerrarMenu">
            <img src={IconoCerrar} alt="Cerrar" />
          </button>
          <div className="contenedorPerfilMovil">
            <img
              id="imagenPerfil"
              src={this.state.imagenPerfil}
              alt="Imagen de usuario"
            />

            <p id="navUserName">{this.state.user}</p>

            <div className="botonesAccion">
              <Link to="/detalles">
                <button className="perfil">
                  {this.state.boot.titular.perfil}
                </button>
              </Link>
              <button className="salir" onClick={(e) => this.logoutConfirm()}>
                {this.state.boot.titular.sesion}
              </button>
            </div>
          </div>
          <button onClick={(e) => this.showBlock(1)}>
            {this.state.boot.menu.cuenta_trading}
          </button>
          <div className="enlaces" id="bloque1">
            <Link to="/">{this.state.boot.menu.resumen}</Link>
            <Link to="/actividad">{this.state.boot.menu.actividad}</Link>
            <Link to="/transacciones">
              {this.state.boot.menu.transacciones}
            </Link>
          </div>
          <button onClick={(e) => this.showBlock(2)}>
            {this.state.boot.menu.perfil_usuario}
          </button>
          <div className="enlaces" id="bloque2">
            <Link to="/detalles">{this.state.boot.menu.detalles}</Link>
            <Link to="/documentos">{this.state.boot.menu.documentos}</Link>
            <Link to="/contrasena">{this.state.boot.menu.contrasena}</Link>
          </div>
          <button onClick={(e) => this.showBlock(3)}>
            {this.state.boot.menu.transferencia}
          </button>
          <div className="enlaces" id="bloque3">
            <Link to="/depositar">{this.state.boot.menu.depositar}</Link>
            <Link to="/extraer">{this.state.boot.menu.extraer}</Link>
          </div>
          <button onClick={(e) => this.showBlock(4)}>
            {this.state.boot.menu.plataforma}
          </button>
          <div className="enlaces" id="bloque4">
            <Link to="/plataforma">{this.state.boot.menu.plataforma_web}</Link>
            <a
              href="https://www.orotrader.com/robots"
              target="_blank"
              rel="noopener noreferrer"
            >
              Orobot
            </a>
            <a
              href="https://client.orotrader.com/orowallet/"
              target="_blank"
              rel="noopener noreferrer"
            >
              OroWallet
            </a>
            <a
              href="https://orotrader.com/signals/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Signals
            </a>
          </div>
          <button onClick={(e) => this.showBlock(5)}>
            {this.state.boot.menu.soporte}
          </button>
          <div className="enlaces" id="bloque5">
            <Link to="/tickets">{this.state.boot.menu.tickets}</Link>
            <a href="http://support.orotrader.com/" target="_new">
              {this.state.boot.menu.contactanos}
            </a>
          </div>

          {/* <button onClick={(e) => this.showBlock(6)}>
            {this.state.boot.menu.club}
          </button>
          <div className="enlaces" id="bloque6">
            <Link to="/referidos">{this.state.boot.menu.referidos}</Link>
          </div> */}
        </div>
        <button onClick={(e) => this.showMenu()} className="abrirMenu">
          <img src={IconoMenu} alt="" />
        </button>
      </>
    );
  }
}
