export function eraseCookie() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  window.location.replace("https://www.orotrader.com");
}
export function eraseCookie2() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
export function getUrlParms(name) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getApiBase() {
  // return "http://192.168.217.8:5001";
  return "https://webservicesnt.org:4447";
}

export function numberFormat(number) {
  if (typeof number == "undefined") {
    return 0;
  }
  number += "";
  let x = number.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  return parseFloat(x1 + x2).toFixed(2);
}

export function getBoot() {
  return {
    es: {
      titular: {
        nivel: "Nivel:",
        perfil: "Perfil",
        sesion: "Salir",
      },
      menu: {
        cuenta_trading: "Cuenta Trading",
        resumen: "Resumen",
        actividad: "Actividad",
        perfil_usuario: "Perfil de usuario",
        detalles: "Detalles del perfil",
        documentos: "Mis Documentos",
        contrasena: "Administrar contraseña",
        transferencia: "Transferencia de fondos",
        depositar: "Depositar",
        extraer: "Extraer",
        plataforma: "Plataforma",
        plataforma_web: "Plataforma Web",
        soporte: "Soporte",
        transacciones: "Transacciones",
        tickets: "Retiros",
        contactanos: "Contáctanos",
        club: "Orotrader Club",
        referidos: "Invitar amigos",
      },
      footer: {
        titulo1: "Cuentas",
        titulo2: "Acerca de",
        titulo3: "Plataformas",
        titulo4: "Herramientas de comercio",
        titulo5: "Descarga",
        titulo6: "Legales",
        link1: "Programa de afiliación",
        link2: "Tipos de Cuentas",
        link3: "Contáctanos",
        link4: "Academia",
        link5: "Términos y Condiciones",
        link6: "Políticas de privacidad",
        link7: "Política de intereses",
        link8: "Política de ejecución de pedidos",
        link9: "Política de divulgación de riesgo",
      },
      resumen: {
        titulo: "Resumen",
        depositos_totales: "Depositos totales",
        creditos_otorgados: "Creditos otorgados",
        retiros_totales: "Total de retiros",
        operaciones_abiertas: "Operaciones abiertas",
        insertar_fondo: "Insertar fondos",
        cuentas_activas: "Cuentas activas",
        cuentas_demo: "Cuentas demo",
        cuenta: "Cuenta",
        moneda: "Moneda",
        margen: "Margen",
        margen_libre: "Margen libre",
        balance_total: "Balance total",
        estado: "Estado",
        plataforma: "Plataforma",
        acciones: "Acciones",
      },
      perfil: {
        titulo: "Perfil",
        nombre: "Nombre",
        apellidos: "Apellidos",
        correo: "Correo",
        pais: "Pais",
        ciudad: "Ciudad",
        estado: "Estado",
        cp: "C.P.",
        direccion: "Dirección",
        telefono: "Telefono",
        nacimiento: "Fecha de Nacimiento",
        imagen: "Imagen de Perfil",
        guardar: "Guardar",
        cancelar: "Cancelar",
        label_contrasena:
          "Debe contener al menos un número y una letra, por lo que debe tener una longitud de 6 a 12 caracteres (no se permiten caracteres especiales)",
      },
      documentos: {
        texto1: "Mis Documentos",
        texto2:
          "Active su cuenta y obtenga acceso a todas las funciones disponibles cargando su Documento de identidad (ID) y Prueba de residencia (POR) utilizando los siguientes formularios de carga de archivos.",

        texto3: "Documentos de identidad",
        texto3_1: "Frente",
        texto3_2: "Sin Archivos",
        texto4_1: "Detras",

        texto5: "Comprobante de Residencia",
        texto5_1: "Sin Archivos",

        texto6: "Tarjeta de Credito",

        texto8: "Formulario de Verificacion",

        texto9: "Comprobante de Pago",

        texto10: "Carta Poder",
        texto10_2: "Subir Archivo",

        texto11: "Otro",
        texto11_2: "Subir Documento",
        texto11_3:
          "Todos los archivos enviados pasan por un proceso de validación",

        texto12: "Guardar y Enviar",
      },
      contrasena: {
        titulo: "Contraseña",
        parrafo:
          "You can change your current password here. We recommend that you use a password that cannot be easily decrypted. We recommend that you combine letters with numbers. Your personal account will be highly protected with 128-bit SSL encryption that guarantees the security of your personal data and transactions.",
        texto1: "Contraseña actual",
        texto2: "Nueva contraseña",
        texto3: "Repite nueva contraseña",
        texto4: "Guardar cambios",
      },
      club: {
        titulo: "2MERCADOS CLUB",
        texto1: "Invitar a mis amigos",
        texto2: "Referidos",
        texto3: "INVITA A TUS AMIGOS Y GANA HASTA 250 DÓLARES EN BONOS.",
        texto4:
          "Comparte tu pasión por las inversiones invitando a tus amigos a invertir junto a ti y recibirás 50 dólares por cada persona que se registre en la plataforma.",
        texto5: "Comparte este codigo",
      },
      contactanos: {
        titulo: "Contactanos",
        texto1: "¿Te queda alguna duda sobre nuestra plataforma?",
        texto2:
          "No dudes en contactarnos y nuestros especialistas te guiaran durante todo el proceso .",
        texto3: "Nombre",
        texto4: "Correo",
        texto5: "Asunto",
        texto6: "Comentario",
        texto7: "Enviar",
      },
      plataforma: {
        titulo: "Plataforma",
        texto1: "Plataforma web",
        texto2: "SIRIX WEB TRADER",
        texto2_1: "Numero de cuenta",
        texto2_2: "Ir a la plataforma",
        texto3: "DESCARGAR PLATAFORMA PARA PC",
        texto3_1: "Metatrader 4 para PC",
        texto3_2: "Descargar",
        texto4: "DESCARGAR PLATAFORMA PARA MOVIL",
        texto4_1: "Sirix para Android",
        texto4_2: "Sirix para iOS",
        texto4_3: "Descargar",
        texto5: "DESCARGAR ANYDESK PARA PC",
        texto5_1: "Anydesk para PC",
        texto5_2: "Anydesk para Android",
        texto5_3: "Anydesk para IOS",
        texto5_4: "Descargar",
        texto5_5: "Anydesk para MacOs",
      },
      actividad: {
        titulo: "Actividad",
        operaciones: "Operaciones",
        periodo: "Periodo",
        todo: "Todo",
        hoy: "Hoy",
        ayer: "Ayer",
        esteMes: "Este Mes",
        esteAno: "Este Año",
        rango: "Rango",
        ordenar: "Ordenar por",
        fechaEspecifica: "Fecha especifica:",
        aplicar: "Aplicar cambios",
        opAbiertas: "Operaciones Abiertas",
        opCerradas: "Operaciones Cerradas",
        abierto: "Abierto",
        cerrado: "Cerrado",
        Actividad_texto3: "Filter",
        Actividad_texto3_1: "# Orden",
        Actividad_texto3_2: "Fecha",
        Actividad_texto3_3: "Simbolo",
        Actividad_texto3_4: "Precio Aper",
        Actividad_texto3_5: "S/L",
        Actividad_texto3_6: "T/P",
        Actividad_texto3_7: "Precio",
        Actividad_texto3_8: "Swap",
        Actividad_texto3_9: "P/L",
      },
      tickets: {
        abiertos: "Open Tickets",
        cerrados: "Closed Tickets",
        tabla_columna1: "Tickets",
        tabla_columna2: "Cuenta",
        tabla_columna3: "Asunto",
        tabla_columna4: "Monto",
        tabla_columna5: "Moneda",
        tabla_columna6: "Estado",
      },
      transacciones: {
        titulo: "Transacciones",
        abiertas: "Transacciones Abiertas",
        cerradas: "Transacciones Cerradas",
      },
      extraer: {
        texto1: "Extraer Fondos",
        texto2: "Escoge tu cuenta",
        texto3: "Numero de cuenta",
        texto4: "Elige tu cuenta",
        texto4_1: "Moneda",
        texto4_2: "Monto",
        texto5: "Razones por las que extrae los fondos",
        texto5_1: "Comentario",
        texto6: "Enviar",
      },
      depositar: {
        texto1: "Depositar Fondos",
        texto2: "Escoge tu cuenta",
        texto3: "Numero de Cuenta",
        texto4_1: "Tarjetas",
        texto4_2: "Moneda",
        texto4_3: "Provedor",
        texto4_4: "Monto",
        texto4_5: "Eliminar",
        texto4_6: "Depositar",
        texto5: "+Agregar Nueva Tarjeta",

        texto6: "Operaciones en Efectivo",
        texto6_1: "Moneda",
        texto6_2: "Monto",
        texto6_3: "Depositar",

        texto7: "Introduzca los datos de su tarjeta.",
        texto7_1: "Nombre del titular",
        texto8: "Numero de tarjeta",
        texto7_2: "Guardar y continuar",
      },
      upload_img_extensions:
        "Tamaño máximo permitido: 5mb | Extensiones permitidas : JPG, PNG, GIF",
      upload_img_text1: "Subir imagen",
      swal_success_title: "¡Finalizado!",
      swal_success_text1: "La imagen ha sido cargada con éxito",
      swal_success_text2: "Información actualizada con éxito",
      swal_success_text3: "Documentos cargados con éxito",
      swal_success_text4: "Contraseña actualizada con éxito",
      swal_success_text5: "Tarjeta registrada con éxito",
      swal_success_text6: "Tarjeta eliminada con éxito",
      swal_success_text7:
        "Tu solicitud será validada, puedes chear el estatus en Tickets",
      swal_error_title: "Error",
      swal_error_text1: "Algunos archivos no han sido cargados correctamente",
      swal_error_text2:
        "El mensaje no ha podido ser enviado, porfavor reintenta",
      swal_warning_title: "¿Estás Seguro?",
      swal_warning_text1: "Los archivos seleccionados serán enviados",
      swal_warning_text2: "Actualizarás tu contraseña",
      swal_warning_text3: "Cerrarás tu sesión",
      swal_warning_text4: "Registrarás una nueva tarjeta",
      swal_warning_text5: "Eliminarás ésta tarjeta",
      swal_warning_text6: "Depositarás ",
      swal_warning_text7: " en la cuenta: ",
      swal_warning_text8: "Solicitarás un retiro ",
      swal_warning_text9: " de la cuenta: ",
      swal_warning_text10: "Lo sentimos",
      swal_warning_text11: "Usted no puede extraer fondos",
      swal_success_text10: "Enviarás un correo de soporte",
      swal_success_text11: "Nos pondremos en contacto contigo a la brevedad",
      //Errores
      EIPW: "Error: Contraseña incorrecta",
      EINP: "Error: Nueva contraseña no válida",
      EUNP: "Error: Contraseñas no coinciden",
      EISN: "Error: Sesión no válida",
      EAMA: "Error: Acción no válida",
      ECAE: "Error: Tarjeta existente",
      ETSE: "Error: Límite de sesiones alcanzadas",
      EENA: "Error: Extensíon no permitida",
      EINC: "Error: Fallo en inserción",
      ENPF: "Error: Sin parámetros de ejecución, contacta a soporte",
      EUNF: "Error: Usuario no encontrado, contacta a soporte",
      EUNC: "Error: Actualización fallida, intenta de nuevo",
      EMNA: "Error: Método no permitido",
      ETNF: "Error: Token no encontrado",
      EMIF: "Error: Archivo faltante o inválido",
      EMFN: "Error: Nombre del archivo faltante o inválido",
      EISQ: "Error: Fallo en SQL, contacta a soporte",
      EUCS: "Error: No es posible crear la sesion, intenta de nuevo",
      EMCD:
        "Error: Informacion de cliente falta o invalida llenar formulario detalles del perfil",
    },
    en: {
      titular: {
        nivel: "Level:",
        perfil: "Profile",
        sesion: "Log out",
      },
      menu: {
        cuenta_trading: "Trading account",
        resumen: "Resume",
        actividad: "Activity",
        perfil_usuario: "User profile",
        detalles: "Profile details",
        documentos: "Documents",
        contrasena: "Update Password",
        transferencia: "Funds transfer",
        depositar: "Deposit",
        extraer: "Whitdrawal",
        plataforma: "Platform",
        plataforma_web: "Web Platform",
        soporte: "Support",
        transacciones: "Transactions",
        tickets: "Withdrawals",
        contactanos: "Contact us",
        club: "Orotrader Club",
        referidos: "Invite friends",
      },

      footer: {
        titulo1: "Accounts",
        titulo2: "About us",
        titulo3: "Platforms",
        titulo4: "Trading tools",
        titulo5: "Download",
        titulo6: "Legal",
        link1: "Affiliate program",
        link2: "Types of Accounts",
        link3: "Contact us",
        link4: "Academy",
        link5: "Terms and Conditions",
        link6: "Privacy policies",
        link7: "Interest policy",
        link8: "Order execution policy",
        link9: "Risk disclosure policy",
      },
      resumen: {
        titulo: "Resume",
        depositos_totales: "Total deposits",
        creditos_otorgados: "Credits Granted",
        retiros_totales: "Total Withdrawals",
        operaciones_abiertas: "Open Operations",
        insertar_fondo: "Make deposits",
        cuentas_activas: "Active accounts",
        cuentas_demo: "Demo accounts",
        cuenta: "Account",
        moneda: "Currency",
        margen: "Margin",
        margen_libre: "Free margin",
        balance_total: "Total balance",
        estado: "State",
        plataforma: "Platform",
        acciones: "Actions",
      },
      perfil: {
        titulo: "Profile",
        nombre: "Name",
        apellidos: "Last name",
        correo: "Email",
        pais: "Country",
        ciudad: "City",
        estado: "State",
        cp: "C.P.",
        direccion: "Address",
        telefono: "Phone",
        nacimiento: "Birth date",
        imagen: "Profile image",
        guardar: "Save",
        cancelar: "Cancel",
        label_contrasena:
          "It must contain at least one number and one letter, so it must be 6-12 characters long (special characters are not allowed)",
      },
      documentos: {
        texto1: "My Documents",
        texto2:
          "Activate your account and get access to all available functions by uploading your Identity Document (ID) and Proof of Residence (POR) using the following file upload forms.",

        texto3: "Identity Documents",
        texto3_1: "Front",
        texto3_2: "No files ",
        texto4: "Identity Documents",
        texto4_1: "Behind",
        texto4_2: "No files",
        texto5: "Proof of Residence",
        texto5_1: "No files",
        texto6: "Credit card",
        texto6_1: "Front",
        texto6_2: "No files",
        texto7: "Credit card",
        texto7_1: "Behind",
        texto7_2: "No files",
        texto8: "Verification Form",
        texto8_1: "No files",
        texto9: "Proof of payment",
        texto9_1: "No files",
        texto10: "Power Letter",
        texto10_1: "No files",
        texto10_2: "Upload file",
        texto11: "Other",
        texto11_1: "No files",
        texto11_2: "Upload file",
        texto11_3: "These files are pending validation.",
        texto12: "Save and send",
      },
      contrasena: {
        titulo: "Password",
        parrafo:
          "You can change your current password here. We recommend that you use a password that cannot be easily decrypted. We recommend that you combine letters with numbers. Your personal account will be highly protected with 128-bit SSL encryption that guarantees the security of your personal data and transactions.",
        texto1: "Current password",
        texto2: "New Password",
        texto3: "Repeat New Password",
        texto4: "Save Changes",
      },
      club: {
        titulo: "2MERCADOS CLUB",
        texto1: "Invite my friends",
        texto2: "Referrals",
        texto3: "INVITES YOUR FRIENDS AND WINS UP TO 250 DOLLARS IN BONDS.",
        texto4:
          "Share your passion for investments by inviting your friends to invest with you and you will receive $ 50 for each person who registers on the platform.",
        texto5: "Share this code",
      },
      contactanos: {
        titulo: "Contact Us",
        texto1: "DO YOU HAVE ANY DOUBTS ABOUT OUR PLATFORM?",
        texto2:
          "Do not hesitate to contact us and our specialists will guide you throughout the process.",
        texto3: "Name",
        texto4: "Email",
        texto5: "Subject",
        texto6: "Comment",
        texto7: "Send",
      },
      plataforma: {
        titulo: "Platform",
        texto1: "Web platform",
        texto2: "SIRIX WEB TRADER",
        texto2_1: "Numero de cuenta",
        texto2_2: "Go to the platform",
        texto3: "DOWNLOAD PC PLATFORM",
        texto3_1: "Metatrader 4 for PC",
        texto3_2: "Download",
        texto4: "DOWNLOAD MOBILE PLATFORM",
        texto4_1: "Sirix for Android",
        texto4_2: "Sirix para iOS",
        texto4_3: "to download",
        texto5: "DOWNLOAD ANYDESK FOR PC",
        texto5_1: "Anydesk for PC",
        texto5_2: "Anydesk for Android",
        texto5_3: "Anydesk for IOS",
        texto5_4: "to download",
        texto5_5: "Anydesk for MacOs",
      },
      actividad: {
        titulo: "Activity",
        operaciones: "Operations",
        periodo: "Period",
        todo: "All",
        hoy: "Today",
        ayer: "Yesterday",
        esteMes: "This Month",
        esteAno: "This Year",
        rango: "Range",
        ordenar: "Order by",
        fechaEspecifica: "Specific date",
        aplicar: "Apply changes",
        opAbiertas: "Open operations",
        opCerradas: "Closed operations",
        abierto: "Opened",
        cerrado: "Closed",
        Actividad_texto3: "Filter",
        Actividad_texto3_1: "# Order",
        Actividad_texto3_2: "Date",
        Actividad_texto3_3: "Symbol",
        Actividad_texto3_4: "Aper Price",
        Actividad_texto3_5: "S/L",
        Actividad_texto3_6: "T/P",
        Actividad_texto3_7: "Price",
        Actividad_texto3_8: "Swap",
        Actividad_texto3_9: "P/L",
      },
      tickets: {
        abiertos: "Open Tickets",
        cerrados: "Closed Tickets",
        tabla_columna1: "Tickets",
        tabla_columna2: "Account",
        tabla_columna3: "Case",
        tabla_columna4: "Amount",
        tabla_columna5: "Currency",
        tabla_columna6: "Status",
      },
      transacciones: {
        titulo: "Transactions",
        abiertas: "Open transactions",
        cerradas: "Closed transactions",
      },
      extraer: {
        texto1: "Extract Funds",
        texto2: "Choose your account",
        texto3: "Account number",
        texto4: "Choose your account",
        texto4_1: "Currency",
        texto4_2: "Amount",
        texto5: "Reasons for withdrawing funds",
        texto5_1: "Commentary",
        texto6: "Send",
      },
      depositar: {
        texto1: "Deposit Funds",
        texto2: "Choose your account",
        texto3: "Account number",
        texto4_1: "Cards",
        texto4_2: "Currency",
        texto4_3: "Provider",
        texto4_4: "Amount",
        texto4_5: "Remove",
        texto4_6: "To deposit",
        texto5: "+Add New Card",

        texto6: "Cash Transactions",
        texto6_1: "Currency",
        texto6_2: "Amount",
        texto6_3: "Deposit",

        texto7: "Please enter your card details.",
        texto7_1: "Owner's name",
        texto8: "Card number",
        texto7_2: "Save and Continue",
      },
      upload_img_extensions:
        "Maximum size allowed: 5mb | Extensions allowed: JPG, PNG, GIF",
      upload_img_text1: "Upload Image",
      swal_success_title: "Success!",
      swal_success_text1: "The image has been loaded successfully",
      swal_success_text2: "Information updated successfully",
      swal_success_text3: "Documents uploaded successfully",
      swal_success_text4: "Password updated successfully",
      swal_success_text5: "Card registered successfully",
      swal_success_text6: "Card deleted successfully",
      swal_success_text7:
        "Your request will be validated, you can check the status in Tickets",
      swal_error_title: "Error",
      swal_error_text1: "Some files have not been loaded correctly",
      swal_error_text2: "The message could not be sent, please retry",
      swal_warning_title: "Are you sure?",
      swal_warning_text1: "Selected files will be sent",
      swal_warning_text2: "You will update your password",
      swal_warning_text3: "You will close your session",
      swal_warning_text4: "You will register a new card",
      swal_warning_text5: "You will delete this card",
      swal_warning_text6: "You will deposit",
      swal_warning_text7: "in the account:",
      swal_warning_text8: "You will request a withdrawal",
      swal_warning_text9: "of the account:",
      swal_warning_text10: "Sorry",
      swal_warning_text11: "You can't extract funds",
      swal_success_text10: "You will send a support email",
      swal_success_text11: "We will contact you shortly",
      //Errores
      EIPW: "Error: Incorrect password",
      EINP: "Error: Invalid new password",
      EUNP: "Error: Passwords do not match",
      EISN: "Error: Invalid session",
      EAMA: "Error: Invalid action",
      ECAE: "Error: Existing card",
      ETSE: "Error: Limit of sessions reached",
      EENA: "Error: Extension not allowed",
      EINC: "Error: Insertion failed",
      ENPF: "Error: Without execution parameters, contact support",
      EUNF: "Error: User not found, contact support",
      EUNC: "Error: Update failed, try again",
      EMNA: "Error: Method not allowed",
      ETNF: "Error: Token not found",
      EMIF: "Error: File missing or invalid",
      EMFN: "Error: Name of missing or invalid file",
      EISQ: "Error: SQL failed, contact support",
      EUCS: "Error: Unable to create session, try again",
      EMCD:
        "Error: Client data missing or invalid, complete Profile details form",
    },
    pt: {
      titular: {
        nivel: "Nivel:",
        perfil: "Perfil",
        sesion: "Sair",
      },
      menu: {
        cuenta_trading: "Conta de negociação",
        resumen: "Resumo",
        actividad: "Exercício",
        perfil_usuario: "Perfil de usuário",
        detalles: "Detalhes de perfil",
        documentos: "Meus documentos",
        contrasena: "Gerenciar senha",
        transferencia: "Transferência de fundos",
        depositar: "Depositar",
        extraer: "Extrair",
        plataforma: "Plataforma",
        plataforma_web: "Plataforma Web",
        soporte: "Suporte",
        transacciones: "Transações",
        tickets: "Retirado",
        contactanos: "Contate-Nos",
        club: "Orotrader Clube",
        referidos: "Convide amigos",
      },
      footer: {
        titulo1: "Contas",
        titulo2: "Sobre",
        titulo3: "Plataformas",
        titulo4: "Ferramentas de negociação",
        titulo5: "Descarga",
        titulo6: "Legal",
        link1: "Programa de Afiliados",
        link2: "Tipos de contas",
        link3: "Contate-Nos",
        link4: "Academia",
        link5: "Termos e Condições",
        link6: "Políticas de privacidade",
        link7: "Política de juros",
        link8: "Política de execução de pedidos",
        link9: "Política de Divulgação de Risco",
      },
      resumen: {
        titulo: "Resumo",
        depositos_totales: "Depósitos totais",
        creditos_otorgados: "Créditos concedidos",
        retiros_totales: "Retiradas totais",
        operaciones_abiertas: "Negociações abertas",
        insertar_fondo: "Insira fundos",
        cuentas_activas: "Contas ativas",
        cuentas_demo: "Contas de demonstração",
        cuenta: "Conta",
        moneda: "Moeda",
        margen: "Margem",
        margen_libre: "Margem livre",
        balance_total: "Balanço total",
        estado: "Estado",
        plataforma: "Plataforma",
        acciones: "Ações",
      },
      perfil: {
        titulo: "Perfil",
        nombre: "Nome",
        apellidos: "Apellidos",
        correo: "Enviar",
        pais: "País",
        ciudad: "Cidade",
        estado: "Estado",
        cp: "C.P.",
        direccion: "Endereço",
        telefono: "Telefone",
        nacimiento: "Data de nascimento",
        imagen: "Foto do perfil",
        guardar: "Salve ",
        cancelar: "Cancelar",
        label_contrasena:
          "Deve conter pelo menos um número e uma letra, portanto, deve ter de 6 a 12 caracteres (não são permitidos caracteres especiais)",
      },
      documentos: {
        texto1: "Meus documentos",
        texto2:
          "Ative sua conta e tenha acesso a todos os recursos disponíveis, carregando seu Documento de Identidade (ID) e Comprovante de Residência (POR) usando os seguintes formulários de upload de arquivo.",

        texto3: "Documentos de identidade",
        texto3_1: "Frente",
        texto3_2: "Sem arquivos",
        texto4_1: "Atrás",

        texto5: "Comprovante de residência",
        texto5_1: "Sem arquivos",

        texto6: "Cartão de crédito",

        texto8: "Formulário de Verificação",

        texto9: "Comprovante de pagamento",

        texto10: "Procuração",
        texto10_2: "Subir arquivo",

        texto11: "De outros",
        texto11_2: "Carregar Documento",
        texto11_3:
          "Todos os arquivos enviados passam por um processo de validação",

        texto12: "Manter e enviar",
      },
      contrasena: {
        titulo: "Senha",
        parrafo:
          "Você pode alterar sua senha atual aqui. Recomendamos que você use uma senha que não possa ser descriptografada facilmente. Recomendamos que você combine letras com números. Sua conta pessoal estará altamente protegida com criptografia SSL de 128 bits que garante a segurança de seus dados pessoais e transações.",
        texto1: "Senha atual",
        texto2: "Nova senha",
        texto3: "Repita para nova senha",
        texto4: "Guardar mudanças",
      },
      club: {
        titulo: "2MERCADOS CLUB",
        texto1: "Convide meus amigos",
        texto2: "Referências",
        texto3: "CONVIDE SEUS AMIGOS E GANHE ATÉ 250 DÓLARES EM BÔNUS.",
        texto4:
          "Compartilhe sua paixão por investir convidando seus amigos a investirem com você e você receberá US $ 50 para cada pessoa que se registrar na plataforma.",
        texto5: "Compartilhe este código",
      },
      contactanos: {
        titulo: "Contate-Nos",
        texto1: "Você tem alguma dúvida sobre nossa plataforma?",
        texto2:
          "Não hesite em nos contactar e os nossos especialistas irão orientá-lo durante todo o processo.",
        texto3: "Nome",
        texto4: "Correo",
        texto5: "Caso",
        texto6: "Comentário",
        texto7: "Enviar",
      },
      plataforma: {
        titulo: "Plataforma",
        texto1: "Plataforma web",
        texto2: "SIRIX WEB TRADER",
        texto2_1: "Numero de conta",
        texto2_2: "Vá para a plataforma",
        texto3: "BAIXAR PLATAFORMA DE PC",
        texto3_1: "Metatrader 4 para PC",
        texto3_2: "baixar",
        texto4: "BAIXAR PLATAFORMA MÓVEL",
        texto4_1: "Sirix para Android",
        texto4_2: "Sirix para iOS",
        texto4_3: "baixar",
        texto5: "BAIXAR ANYDESK PARA PC",
        texto5_1: "Anydesk para PC",
        texto5_2: "Anydesk para Android",
        texto5_3: "Anydesk para IOS",
        texto5_4: "baixar",
        texto5_5: "Anydesk para MacOs",
      },
      actividad: {
        titulo: "Exercício",
        operaciones: "Operaciones",
        periodo: "Período",
        todo: "Tudo",
        hoy: "Hoje",
        ayer: "Ontem",
        esteMes: "Este mês",
        esteAno: "Este Año",
        rango: "Classificação",
        ordenar: "Organizar por",
        fechaEspecifica: "Data específica:",
        aplicar: "Aplicar mudanças",
        opAbiertas: "Operações Abertas",
        opCerradas: "Operações Fechadas",
        abierto: "Abrir",
        cerrado: "Fechadas",
        Actividad_texto3: "Filtro",
        Actividad_texto3_1: "# Ordem",
        Actividad_texto3_2: "Encontro",
        Actividad_texto3_3: "Símbolo",
        Actividad_texto3_4: "Preço Aper",
        Actividad_texto3_5: "S/L",
        Actividad_texto3_6: "T/P",
        Actividad_texto3_7: "Preço",
        Actividad_texto3_8: "Troca",
        Actividad_texto3_9: "P/L",
      },
      tickets: {
        abiertos: "Ingressos abertos",
        cerrados: "Ingressos Fechados",
        tabla_columna1: "Ingressos",
        tabla_columna2: "Conta",
        tabla_columna3: "Caso",
        tabla_columna4: "Montante",
        tabla_columna5: "Moeda",
        tabla_columna6: "Estado",
      },
      transacciones: {
        titulo: "Transações",
        abiertas: "Transações abertas",
        cerradas: "Transações Fechadas",
      },
      extraer: {
        texto2: "Escolha sua conta",
        texto3: "Numero de conta",
        texto4: "Escolha sua conta",
        texto4_1: "Moeda",
        texto4_2: "Montante",
        texto5: "Razões pelas quais você retira os fundos",
        texto5_1: "Comentário",
        texto6: "Enviar",
      },
      depositar: {
        texto1: "Fundos de depósito",
        texto2: "Escolha sua conta",
        texto3: "Numero de conta",
        texto4_1: "Cartas",
        texto4_2: "Moeda",
        texto4_3: "Fornecedor",
        texto4_4: "Montante",
        texto4_5: "Remover",
        texto4_6: "Depositar",
        texto5: "+Adicionar novo cartão",

        texto6: "Operações de caixa",
        texto6_1: "Moeda",
        texto6_2: "Montante",
        texto6_3: "Depositar",

        texto7: "Insira os dados do seu cartão.",
        texto7_1: "nome do titular",
        texto8: "Número de cartão",
        texto7_2: "Salve e continue",
      },
      upload_img_extensions:
        "Tamanho máximo permitido: 5mb | Extensões permitidas: JPG, PNG, GIF",
      upload_img_text1: "Enviar Imagem",
      swal_success_title: "Finalizado!",
      swal_success_text1: "A imagem foi enviada com sucesso",
      swal_success_text2: "Informação atualizada com sucesso",
      swal_success_text3: "Documentos enviados com sucesso",
      swal_success_text4: "Senha atualizada com sucesso",
      swal_success_text5: "Cartão registrado com sucesso",
      swal_success_text6: "Cartão removido com sucesso",
      swal_success_text7:
        "Seu pedido será validado, você pode verificar o status em Tickets",
      swal_error_title: "Error",
      swal_error_text1: "Alguns arquivos não foram carregados corretamente",
      swal_error_text2: "A mensagem não pôde ser enviada, tente novamente",
      swal_warning_title: "Tem certeza?",
      swal_warning_text1: "Os arquivos selecionados serão enviados",
      swal_warning_text2: "Você irá atualizar sua senha",
      swal_warning_text3: "Você vai fechar sua sessão",
      swal_warning_text4: "Você vai registrar um novo cartão",
      swal_warning_text5: "Você vai deletar este cartão",
      swal_warning_text6: "Você vai depositar ",
      swal_warning_text7: "na conta: ",
      swal_warning_text8: "Você vai solicitar um saque ",
      swal_warning_text9: " da conta:",
      swal_warning_text10: "Sentimos muito",
      swal_warning_text11: "Você não pode retirar fundos",
      swal_success_text10: "Você enviará um e-mail de suporte",
      swal_success_text11: "Entraremos em contato com você em breve",
      //Errores
      EIPW: "Erro: senha errada",
      EINP: "Erro: nova senha inválida",
      EUNP: "Erro: as senhas não correspondem",
      EISN: "Erro: Sessão inválida",
      EAMA: "Erro: ação inválida",
      ECAE: "Erro: cartão existente",
      ETSE: "Erro: Limite de sessões atingido",
      EENA: "Erro: extensão não permitida",
      EINC: "Erro: Falha na inserção",
      ENPF: "Erro: sem parâmetros de execução, entre em contato com o suporte",
      EUNF: "Erro: usuário não encontrado, entre em contato com o suporte",
      EUNC: "Erro: Falha na atualização, tente novamente",
      EMNA: "Erro: Método não permitido",
      ETNF: "Erro: Token não encontrado",
      EMIF: "Erro: arquivo ausente ou inválido",
      EMFN: "Error: nombre de archivo no válido o faltante",
      EISQ: "Error: Fallo en SQL, póngase en contacto con el soporte",
      EUCS: "Error: no es posible crear la sesión, inténtalo de nuevo",
      EMCD:
        "Error: falta información del cliente o invalida los detalles del perfil",
    },
  };
}
export function getCountries() {
  return {
    BD: "Bangladesh",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BA: "Bosnia and Herzegovina",
    BB: "Barbados",
    WF: "Wallis and Futuna",
    BL: "Saint Barthelemy",
    BM: "Bermuda",
    BN: "Brunei",
    BO: "Bolivia",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BT: "Bhutan",
    JM: "Jamaica",
    BV: "Bouvet Island",
    BW: "Botswana",
    WS: "Samoa",
    BQ: "Bonaire, Saint Eustatius and Saba ",
    BR: "Brazil",
    BS: "Bahamas",
    JE: "Jersey",
    BY: "Belarus",
    BZ: "Belize",
    RU: "Russia",
    RW: "Rwanda",
    RS: "Serbia",
    TL: "East Timor",
    RE: "Reunion",
    TM: "Turkmenistan",
    TJ: "Tajikistan",
    RO: "Romania",
    TK: "Tokelau",
    GW: "Guinea-Bissau",
    GU: "Guam",
    GT: "Guatemala",
    GS: "South Georgia and the South Sandwich Islands",
    GR: "Greece",
    GQ: "Equatorial Guinea",
    GP: "Guadeloupe",
    JP: "Japan",
    GY: "Guyana",
    GG: "Guernsey",
    GF: "French Guiana",
    GE: "Georgia",
    GD: "Grenada",
    GB: "United Kingdom",
    GA: "Gabon",
    SV: "El Salvador",
    GN: "Guinea",
    GM: "Gambia",
    GL: "Greenland",
    GI: "Gibraltar",
    GH: "Ghana",
    OM: "Oman",
    TN: "Tunisia",
    JO: "Jordan",
    HR: "Croatia",
    HT: "Haiti",
    HU: "Hungary",
    HK: "Hong Kong",
    HN: "Honduras",
    HM: "Heard Island and McDonald Islands",
    VE: "Venezuela",
    PR: "Puerto Rico",
    PS: "Palestinian Territory",
    PW: "Palau",
    PT: "Portugal",
    SJ: "Svalbard and Jan Mayen",
    PY: "Paraguay",
    IQ: "Iraq",
    PA: "Panama",
    PF: "French Polynesia",
    PG: "Papua New Guinea",
    PE: "Peru",
    PK: "Pakistan",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    ZM: "Zambia",
    EH: "Western Sahara",
    EE: "Estonia",
    EG: "Egypt",
    ZA: "South Africa",
    EC: "Ecuador",
    IT: "Italy",
    VN: "Vietnam",
    SB: "Solomon Islands",
    ET: "Ethiopia",
    SO: "Somalia",
    ZW: "Zimbabwe",
    SA: "Saudi Arabia",
    ES: "Spain",
    ER: "Eritrea",
    ME: "Montenegro",
    MD: "Moldova",
    MG: "Madagascar",
    MF: "Saint Martin",
    MA: "Morocco",
    MC: "Monaco",
    UZ: "Uzbekistan",
    MM: "Myanmar",
    ML: "Mali",
    MO: "Macao",
    MN: "Mongolia",
    MH: "Marshall Islands",
    MK: "Macedonia",
    MU: "Mauritius",
    MT: "Malta",
    MW: "Malawi",
    MV: "Maldives",
    MQ: "Martinique",
    MP: "Northern Mariana Islands",
    MS: "Montserrat",
    MR: "Mauritania",
    IM: "Isle of Man",
    UG: "Uganda",
    TZ: "Tanzania",
    MY: "Malaysia",
    MX: "Mexico",
    IL: "Israel",
    FR: "France",
    IO: "British Indian Ocean Territory",
    SH: "Saint Helena",
    FI: "Finland",
    FJ: "Fiji",
    FK: "Falkland Islands",
    FM: "Micronesia",
    FO: "Faroe Islands",
    NI: "Nicaragua",
    NL: "Netherlands",
    NO: "Norway",
    NA: "Namibia",
    VU: "Vanuatu",
    NC: "New Caledonia",
    NE: "Niger",
    NF: "Norfolk Island",
    NG: "Nigeria",
    NZ: "New Zealand",
    NP: "Nepal",
    NR: "Nauru",
    NU: "Niue",
    CK: "Cook Islands",
    XK: "Kosovo",
    CI: "Ivory Coast",
    CH: "Switzerland",
    CO: "Colombia",
    CN: "China",
    CM: "Cameroon",
    CL: "Chile",
    CC: "Cocos Islands",
    CA: "Canada",
    CG: "Republic of the Congo",
    CF: "Central African Republic",
    CD: "Democratic Republic of the Congo",
    CZ: "Czech Republic",
    CY: "Cyprus",
    CX: "Christmas Island",
    CR: "Costa Rica",
    CW: "Curacao",
    CV: "Cape Verde",
    CU: "Cuba",
    SZ: "Swaziland",
    SY: "Syria",
    SX: "Sint Maarten",
    KG: "Kyrgyzstan",
    KE: "Kenya",
    SS: "South Sudan",
    SR: "Suriname",
    KI: "Kiribati",
    KH: "Cambodia",
    KN: "Saint Kitts and Nevis",
    KM: "Comoros",
    ST: "Sao Tome and Principe",
    SK: "Slovakia",
    KR: "South Korea",
    SI: "Slovenia",
    KP: "North Korea",
    KW: "Kuwait",
    SN: "Senegal",
    SM: "San Marino",
    SL: "Sierra Leone",
    SC: "Seychelles",
    KZ: "Kazakhstan",
    KY: "Cayman Islands",
    SG: "Singapore",
    SE: "Sweden",
    SD: "Sudan",
    DO: "Dominican Republic",
    DM: "Dominica",
    DJ: "Djibouti",
    DK: "Denmark",
    VG: "British Virgin Islands",
    DE: "Germany",
    YE: "Yemen",
    DZ: "Algeria",
    US: "United States",
    UY: "Uruguay",
    YT: "Mayotte",
    UM: "United States Minor Outlying Islands",
    LB: "Lebanon",
    LC: "Saint Lucia",
    LA: "Laos",
    TV: "Tuvalu",
    TW: "Taiwan",
    TT: "Trinidad and Tobago",
    TR: "Turkey",
    LK: "Sri Lanka",
    LI: "Liechtenstein",
    LV: "Latvia",
    TO: "Tonga",
    LT: "Lithuania",
    LU: "Luxembourg",
    LR: "Liberia",
    LS: "Lesotho",
    TH: "Thailand",
    TF: "French Southern Territories",
    TG: "Togo",
    TD: "Chad",
    TC: "Turks and Caicos Islands",
    LY: "Libya",
    VA: "Vatican",
    VC: "Saint Vincent and the Grenadines",
    AE: "United Arab Emirates",
    AD: "Andorra",
    AG: "Antigua and Barbuda",
    AF: "Afghanistan",
    AI: "Anguilla",
    VI: "U.S. Virgin Islands",
    IS: "Iceland",
    IR: "Iran",
    AM: "Armenia",
    AL: "Albania",
    AO: "Angola",
    AQ: "Antarctica",
    AS: "American Samoa",
    AR: "Argentina",
    AU: "Australia",
    AT: "Austria",
    AW: "Aruba",
    IN: "India",
    AX: "Aland Islands",
    AZ: "Azerbaijan",
    IE: "Ireland",
    ID: "Indonesia",
    UA: "Ukraine",
    QA: "Qatar",
    MZ: "Mozambique",
  };
}
