import React, { Component } from "react";
import { getCookie, numberFormat, getBoot, getApiBase } from "../../../utils";
import Loading from "../../../assets/images/Loading.gif";
import Griddle, {
  RowDefinition,
  ColumnDefinition,
  plugins,
} from "griddle-react";
import Menu from "../../../components/menu/menu.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./actividad.scss";

const layoutTablas = ({ Table, Pagination, Filter }) => (
  <div className="tabla">
    <Filter />
    <Table />
    <Pagination />
  </div>
);

const styleConfig = {
  classNames: {
    Table: "tablaGriddle",
  },
  styles: {
    Filter: { fontSize: 18, float: "right", marginBottom: "10px" },
    Table: {
      width: "100%",
      textAlign: "Center",
    },
    TableHeading: { color: "white", background: "#C4C4C4" },
    TableHeadingCell: { padding: "14px 0" },
    Cell: { padding: "14px 0" },
    Pagination: { float: "right", marginBottom: "10px" },
  },
};
export default class Actividad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boot: getBoot()[getCookie("OroClientlang")],
      data: {
        summary: {},
        tp_accounts: [],
        tp_accounts_d: [],
      },
      data_completas: {},
      start_date: new Date(),
      end_date: new Date(),
      loading: true,
      closed: [],
      open: [],
      show_data: [],
    };
  }
  componentDidMount() {
    this.getActivity();
  }
  getActivity = () => {
    //const that = this;
    fetch(getApiBase() + "/get/CustomerData?selection=tp_accounts", {
      method: "POST",
      body: JSON.stringify({
        session_id: getCookie("session_id"),
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          data: response.data,
          loading: false,
          data_completas: response.data,
          show_data: response.data.tp_accounts[0].positions,
        });
        this.iniciaTabla();
      });
  };

  renderTabla = (data) => {
    let tabla = [];
    data.forEach((cuenta) => {
      tabla.push(
        <div key={cuenta.data.login} className="tablaResumen">
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.cuenta}</span>
            <p>{cuenta.data.login}</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.moneda}</span>
            <p>{cuenta.data.currency}</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.margen}</span>
            <p>{cuenta.data.margin}</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.margen_libre}</span>
            <p>{cuenta.data.margin_free}</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.balance_total}</span>
            <p>{cuenta.data.equity}</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.estado}</span>
            <p>{cuenta.data.enable}</p>
          </div>
          <div className="celdaResumen">
            <span>{this.state.boot.resumen.plataforma}</span>
            <p>{cuenta.data.platform}</p>
          </div>
        </div>
      );
    });
    return tabla;
  };

  iniciaTabla = () => {
    console.log(this.state.data.tp_accounts[0].positions);
  };

  updateDate = (d, a) => {
    if (a === 1) this.setState({ start_date: d });
    else if (a === 2) this.setState({ end_date: d });
    else console.log("ENPF");
  };

  filtrar(boton, e) {
    e.preventDefault();
    if (boton === "fechas") {
      let tp = document.getElementById("tp_account").value;
      let fecha, dia, mes, año, fecha_inicio, fecha_fin;
      console.log(this.state);
      switch (document.getElementById("periodo").value) {
        case "Hoy":
          fecha = new Date();
          dia = fecha.getDate();
          mes = fecha.getMonth() + 1;
          año = fecha.getFullYear();
          fecha = año + "-" + mes + "-" + dia + " 00:00";
          let aux = this.state.data_completas.tp_accounts;
          let aux1 = [];
          for (let i = 0; i < aux.length; i++) {
            if (aux[i].data.login === parseInt(tp)) {
              for (let j = 0; j < aux[i].positions.length; j++) {
                if (
                  new Date(aux[i].positions[j].open_time) >= new Date(fecha)
                ) {
                  aux1.push(aux[i].positions[j]);
                }
              }
              aux[i].positions = aux1;
            }
          }

          this.setState({ data: { tp_accounts: aux } });
          break;
        case "Ayer":
          fecha = new Date();
          dia = fecha.getDate() - 1;
          mes = fecha.getMonth() + 1;
          año = fecha.getFullYear();
          fecha_inicio = año + "-" + mes + "-" + dia + " 00:00";
          fecha_fin = año + "-" + mes + "-" + dia + " 23:59";
          let aux3 = this.state.data_completas.tp_accounts;
          let aux4 = [];
          for (let i = 0; i < aux3.length; i++) {
            if (aux3[i].data.login === parseInt(tp)) {
              for (let j = 0; j < aux3[i].positions.length; j++) {
                if (
                  new Date(aux3[i].positions[j].open_time) >=
                    new Date(fecha_inicio) &&
                  new Date(aux3[i].positions[j].open_time) <=
                    new Date(fecha_fin)
                ) {
                  aux4.push(aux3[i].positions[j]);
                }
              }
              aux3[i].positions = aux4;
            }
          }
          this.setState({ data: { tp_accounts: aux3 } });
          break;
        case "Este mes":
          fecha = new Date();
          dia = "1";
          mes = fecha.getMonth() + 1;
          año = fecha.getFullYear();
          fecha_inicio = año + "-" + mes + "-" + dia + " 00:00";
          let aux5 = this.state.data_completas.tp_accounts;
          let aux6 = [];
          for (let i = 0; i < aux5.length; i++) {
            if (aux5[i].data.login === parseInt(tp)) {
              for (let j = 0; j < aux5[i].positions.length; j++) {
                if (
                  new Date(aux5[i].positions[j].open_time) >=
                    new Date(fecha_inicio) &&
                  new Date(aux5[i].positions[j].open_time) <= new Date()
                ) {
                  aux6.push(aux5[i].positions[j]);
                }
              }
              aux5[i].positions = aux6;
            }
          }
          this.setState({ data: { tp_accounts: aux5 } });
          break;
        case "Este anho":
          fecha = new Date();
          dia = "1";
          mes = "1";
          año = fecha.getFullYear();
          fecha_inicio = año + "-" + mes + "-" + dia + " 00:00";
          let aux7 = this.state.data_completas.tp_accounts;
          let aux8 = [];
          for (let i = 0; i < aux7.length; i++) {
            if (aux7[i].data.login === parseInt(tp)) {
              for (let j = 0; j < aux7[i].positions.length; j++) {
                if (
                  new Date(aux7[i].positions[j].open_time) >=
                    new Date(fecha_inicio) &&
                  new Date(aux7[i].positions[j].open_time) <= new Date()
                ) {
                  aux8.push(aux7[i].positions[j]);
                }
              }
              aux7[i].positions = aux8;
            }
          }
          this.setState({ data: { tp_accounts: aux7 } });
          break;
        default:
          let aux2 = {};
          let aux9 = this.state.data_completas.tp_accounts;
          for (
            let i = 0;
            i < this.state.data_completas.tp_accounts.length;
            i++
          ) {
            if (
              this.state.data_completas.tp_accounts[i].data.login ===
              parseInt(tp)
            ) {
              aux2 = this.state.data_completas.tp_accounts[i];
            }
            aux9[i] = aux2;
          }
          this.setState({ data: { tp_accounts: aux9 } });
          break;
      }
    } else if (boton === "activo") {
      let tp = document.getElementById("tp_account").value;
      let aux = this.state.data_completas.tp_accounts;
      let info = [];
      for (let i = 0; i < aux.length; i++) {
        if (aux[i].data.login === tp) {
          info = aux[i].open;
        }
      }
      console.log(info);
      this.setState({ show_data: info });
    } else if (boton === "inactivo") {
      let tp = document.getElementById("tp_account").value;
      let aux = this.state.data_completas.tp_accounts;
      let info = [];
      for (let i = 0; i < aux.length; i++) {
        if (aux[i].data.login === tp) {
          info = aux[i].closed;
        }
      }
      console.log(info);
      this.setState({ show_data: info });
    }
  }
  renderTps = () => {
    let tps = JSON.parse(getCookie("tp_accounts"));
    let cuenta = [];
    tps.forEach((element) => {
      let sel = element.tp_id === this.state.selected_tp ? "true" : "false";
      cuenta.push(
        <option selected={sel} value={element.tp_id}>
          {element.tp_id}
        </option>
      );
    });
    return cuenta;
  };

  checkRange = () => {
    let x = document.getElementById("periodo").value;
    if (x === "Rango") {
      document.getElementById("start_date").style.pointerEvents = "auto";
      document.getElementById("end_date").style.pointerEvents = "auto";
    } else {
      document.getElementById("start_date").style.pointerEvents = "none";
      document.getElementById("end_date").style.pointerEvents = "none";
    }
  };

  render() {
    const CustomColumn = ({ value }) => (
      <span style={{ fontSize: "12px" }}>{value}</span>
    );
    const CustomColumnNumber = ({ value }) => (
      <span style={{ fontSize: "12px" }}>$ {numberFormat(value)}</span>
    );
    let start_date = new Date(this.state.start_date);
    let end_date = new Date(this.state.end_date);
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="1" />
            <div className="principal actividad">
              {!this.state.loading && (
                <>
                  <div className="contenedorCuenta">
                    <h1>{this.state.boot.actividad.titulo}</h1>
                    <div className="selectorCuenta">
                      <span>{this.state.boot.extraer.texto3}</span>
                      <select id="tp_account">{this.renderTps()}</select>
                    </div>
                  </div>

                  {this.renderTabla(this.state.data.tp_accounts)}

                  <div className="contenedorFiltros">
                    <h1>{this.state.boot.actividad.operaciones}</h1>
                    <div className="filtros">
                      <div className="periodoBusqueda">
                        <span>{this.state.boot.actividad.periodo}</span>
                        <select
                          name="periodo"
                          id="periodo"
                          onChange={(e) => this.checkRange()}
                        >
                          <option value="todo">
                            {this.state.boot.actividad.todo}
                          </option>
                          <option value="Hoy">
                            {this.state.boot.actividad.hoy}
                          </option>
                          <option value="Ayer">
                            {this.state.boot.actividad.ayer}
                          </option>
                          <option value="Este mes">
                            {this.state.boot.actividad.esteMes}
                          </option>
                          <option value="Este anho">
                            {this.state.boot.actividad.esteAno}
                          </option>
                          <option value="Rango">
                            {this.state.boot.actividad.rango}
                          </option>
                        </select>
                      </div>
                      <div className="datePicker">
                        <span>{this.state.boot.actividad.fechaEspecifica}</span>
                        <DatePicker
                          id="start_date"
                          selected={start_date}
                          dateFormat="yyyy-MM-dd"
                          maxDate={new Date()}
                          onChange={(date) => this.updateDate(date, 1)}
                          className="timepicker-input"
                          // disabled
                        />
                        <DatePicker
                          id="end_date"
                          selected={end_date}
                          dateFormat="yyyy-MM-dd"
                          minDate={this.state.start_date}
                          maxDate={new Date()}
                          onChange={(date) => this.updateDate(date, 2)}
                          className="timepicker-input"
                          // disabled
                        />
                      </div>
                      <div className="aplicarCambios">
                        <button
                          className="boton botonAmarillo"
                          onClick={(e) => this.filtrar("fechas", e)}
                        >
                          {this.state.boot.actividad.aplicar}
                        </button>
                      </div>
                    </div>
                    <div className="contenedorTabla">
                      <div className="selectorOperaciones">
                        <button
                          className="boton"
                          onClick={(e) => this.filtrar("activo", e)}
                        >
                          {this.state.boot.actividad.opAbiertas}
                        </button>
                        <button
                          className="boton"
                          onClick={(e) => this.filtrar("inactivo", e)}
                        >
                          {this.state.boot.actividad.opCerradas}
                        </button>
                      </div>
                      <div className="ticketsAbiertos">
                        <Griddle
                          pageProperties={{
                            pageSize: 10,
                          }}
                          data={this.state.show_data}
                          plugins={[plugins.LocalPlugin]}
                          components={{
                            Layout: layoutTablas,
                          }}
                          styleConfig={styleConfig}
                        >
                          <RowDefinition>
                            <ColumnDefinition
                              width={120}
                              id="is_open"
                              title={
                                this.state.boot.actividad.Actividad_texto3_1
                              }
                              customComponent={CustomColumn}
                            />
                            <ColumnDefinition
                              width={120}
                              id="open_time"
                              title={
                                this.state.boot.actividad.Actividad_texto3_2
                              }
                              customComponent={CustomColumn}
                            />
                            <ColumnDefinition
                              width={120}
                              id="symbol"
                              title={
                                this.state.boot.actividad.Actividad_texto3_3
                              }
                              customComponent={CustomColumn}
                            />
                            <ColumnDefinition
                              width={120}
                              id="open_price"
                              title={
                                this.state.boot.actividad.Actividad_texto3_4
                              }
                              customComponent={CustomColumnNumber}
                            />
                            <ColumnDefinition
                              width={120}
                              id="sl"
                              title={
                                this.state.boot.actividad.Actividad_texto3_5
                              }
                              customComponent={CustomColumnNumber}
                            />
                            <ColumnDefinition
                              width={120}
                              id="tp"
                              title={
                                this.state.boot.actividad.Actividad_texto3_6
                              }
                              customComponent={CustomColumnNumber}
                            />
                            <ColumnDefinition
                              width={120}
                              id="volume"
                              title={
                                this.state.boot.actividad.Actividad_texto3_7
                              }
                              customComponent={CustomColumn}
                            />
                            <ColumnDefinition
                              width={120}
                              id="swaps"
                              title={
                                this.state.boot.actividad.Actividad_texto3_8
                              }
                              customComponent={CustomColumn}
                            />
                            <ColumnDefinition
                              width={120}
                              id="profit"
                              title={
                                this.state.boot.actividad.Actividad_texto3_9
                              }
                              customComponent={CustomColumnNumber}
                            />
                          </RowDefinition>
                        </Griddle>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {this.state.loading && (
                <img className="loading" alt="Loading" src={Loading} />
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}
