import React, { Component } from "react";
import Swiper from "react-id-swiper";
import Cards from "../../assets/images/card.png";
import "swiper/css/swiper.css";
import "./tarjetas1.scss";

export default class Tarjetas extends Component {
  state = {
    tarjetas: this.props.data.cards,
  };

  componentDidMount() {}
  renderCards = () => {
    let Tarjeta = [];
    let metodos = this.state.tarjetas;

    if (metodos === "" || metodos === undefined || metodos.length === 0) {
      Tarjeta.push(
        <div key="tarjetaMuestra" className="tarjetaUnica">
          <img src={Cards} alt="" />
          <p className="numeroTarjeta">00xx-xxxx-xxxx-0000</p>
          <p className="nombreTarjeta">xxxx xxxxx xxxx</p>
        </div>
      );
    } else {
      metodos.forEach((metodo) => {
        Tarjeta.push(
          <div key={metodo.card_number} className="tarjetaUnica">
            <img src={Cards} alt="" />
            <p className="numeroTarjeta">{metodo.card_number}</p>
            <p className="nombreTarjeta">{metodo.holder_name}</p>
          </div>
        );
      });
    }
    return Tarjeta;
  };
  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
    return <Swiper {...params}>{this.renderCards()}</Swiper>;
  }
}
