import React, { Component } from "react";
import { getCookie, getBoot, getApiBase } from "../../../utils";

import Menu from "../../../components/menu/menu.jsx";
import "./documentos.scss";
import swal from "sweetalert";

export default class Documentos extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
    uploadDocs: {},
  };

  getInputValue = (e, id) => {
    let aux = this.state.uploadDocs;
    aux[id] = e.target.files[0];
    this.setState({ uploadDocs: aux });
    let x = document.getElementById(id).value;
    let y = x.replace(/^C:\\fakepath\\/, "");
    let a = document.getElementById(id + "1");
    a.value = y;
    // console.log(this.state.uploadDocs);
  };

  insertBulkDocuments = (e) => {
    e.preventDefault();
    let aux = this.state.uploadDocs;
    Object.keys(aux).forEach((key) => {
      if (aux[key] !== "") {
        let formDat = new FormData();
        formDat.append("file", aux[key]);
        switch (key) {
          case "identidad":
            formDat.append("tipo", 3);
            formDat.append("sub_tipo", "Front");
            break;
          case "idDetras1":
            formDat.append("tipo", 3);
            formDat.append("sub_tipo", "Back");
            break;
          case "residencia":
            formDat.append("tipo", 2);
            formDat.append("sub_tipo", "");
            break;
          case "creditoFrente":
            formDat.append("tipo", 4);
            formDat.append("sub_tipo", "Front");
            break;
          case "creditoAtras":
            formDat.append("tipo", 4);
            formDat.append("sub_tipo", "Back");
            break;
          case "verificacion":
            formDat.append("tipo", 6);
            formDat.append("sub_tipo", "");
            break;
          case "comprobante":
            formDat.append("tipo", 7);
            formDat.append("sub_tipo", "");
            break;
          default:
            // console.log("muerte XD");
            break;
        }
        formDat.append("session_id", getCookie("session_id"));
        fetch(getApiBase() + "/upload/Files", {
          method: "POST",
          body: formDat,
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.result === 1) {
              swal({
                icon: "success",
                title: this.state.boot["swal_success_title"],
                text: this.state.boot["swal_success_text10"],
              });
            } else {
              swal({
                icon: "error",
                title: "Oops",
                text: this.state.boot[result.error],
              });
            }
          });
      }
    });
  };

  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="4" />
            <div className="principal">
              <h1>{this.state.boot.documentos.texto1}</h1>
              <p>{this.state.boot.documentos.texto2}</p>
              <div className="subidaArchivos">
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto3}</span>
                    <p>{this.state.boot.documentos.texto3_1}</p>
                  </div>
                  <div className="nombreArchivo">
                    <input type="text" name="identidad1" id="identidad1" />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "identidad")}
                      type="file"
                      name="identidad"
                      id="identidad"
                    />
                    <label htmlFor="identidad">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto4}</span>
                    <p>{this.state.boot.documentos.texto4_1}</p>
                  </div>
                  <div className="nombreArchivo">
                    <input type="text" name="idDetras1" id="idDetras1" />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "idDetras")}
                      type="file"
                      name="idDetras"
                      id="idDetras"
                    />
                    <label htmlFor="idDetras">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto5}</span>
                  </div>
                  <div className="nombreArchivo">
                    <input type="text" name="residencia1" id="residencia1" />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "residencia")}
                      type="file"
                      name="residencia"
                      id="residencia"
                    />
                    <label htmlFor="residencia">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto8}</span>
                  </div>
                  <div className="nombreArchivo">
                    <input
                      type="text"
                      name="verificacion1"
                      id="verificacion1"
                    />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "verificacion")}
                      type="file"
                      name="verificacion"
                      id="verificacion"
                    />
                    <label htmlFor="verificacion">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto6}</span>
                    <p>{this.state.boot.documentos.texto6_1}</p>
                  </div>
                  <div className="nombreArchivo">
                    <input
                      type="text"
                      name="creditoFrente1"
                      id="creditoFrente1"
                    />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "creditoFrente")}
                      type="file"
                      name="creditoFrente"
                      id="creditoFrente"
                    />
                    <label htmlFor="creditoFrente">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto6}</span>
                    <p>{this.state.boot.documentos.texto7_1}</p>
                  </div>
                  <div className="nombreArchivo">
                    <input
                      type="text"
                      name="creditoAtras1"
                      id="creditoAtras1"
                    />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "creditoAtras")}
                      type="file"
                      name="creditoAtras"
                      id="creditoAtras"
                    />
                    <label htmlFor="creditoAtras">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
                <div className="archivo">
                  <div className="tipoArchivo">
                    <span>{this.state.boot.documentos.texto9}</span>
                  </div>
                  <div className="nombreArchivo">
                    <input type="text" name="comprobante1" id="comprobante1" />
                  </div>
                  <div className="subirArchivo">
                    <input
                      onChange={(e) => this.getInputValue(e, "comprobante")}
                      type="file"
                      name="comprobante"
                      id="comprobante"
                    />
                    <label htmlFor="comprobante">
                      {this.state.boot.documentos.texto11_2}
                    </label>
                  </div>
                </div>
              </div>
              <div className="enviarArchivos">
                <p>{this.state.boot.documentos.texto11_3}</p>
                <input
                  onClick={(e) => this.insertBulkDocuments(e)}
                  type="button"
                  className="boton"
                  value={this.state.boot.documentos.texto12}
                />
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
