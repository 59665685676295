import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
// import { Link } from "react-router-dom";
import "./footer.css";
import LogoBlanco from "../../assets/images/LogoBlanco.png";
import IconoFB from "../../assets/images/Iconos/facebook.svg";
import IconoIG from "../../assets/images/Iconos/instagram.svg";
import IconoTW from "../../assets/images/Iconos/twitter.svg";
import imgBanner1 from "../../assets/images/Iconos/asset6.png";
import imgBanner2 from "../../assets/images/Iconos/asset7.png";
import imgBanner3 from "../../assets/images/Iconos/asset8.png";
import imgBanner4 from "../../assets/images/Iconos/asset9.png";
import imgBanner5 from "../../assets/images/Iconos/asset10.png";
import imgBanner6 from "../../assets/images/Iconos/asset11.png";
import imgBanner7 from "../../assets/images/Iconos/asset12.png";
import imgBanner8 from "../../assets/images/Iconos/asset13.png";
import imgBanner9 from "../../assets/images/Iconos/asset14.png";
import imgBanner10 from "../../assets/images/Iconos/asset15.png";
import imgBanner11 from "../../assets/images/Iconos/asset16.png";
import imgBanner12 from "../../assets/images/Iconos/asset17.png";
import imgBanner13 from "../../assets/images/Iconos/asset18.png";
import imgBanner14 from "../../assets/images/Iconos/asset19.png";
import imgBanner15 from "../../assets/images/Iconos/asset20.png";

export default class Footer extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
  };
  render() {
    return (
      <>
        <div className="contenedor piePagina">
          <div className="contenedorPagos">
            <div>
              <img src={imgBanner1} alt="Metodo de pago" />
            </div>
            <div>
              <img src={imgBanner2} alt="Metodo de pago" />
            </div>
            <div>
              <img src={imgBanner3} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner4} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner5} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner6} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner7} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner8} alt="Metodo de pago" />
            </div>
            <div>
              <img src={imgBanner9} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner10} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner11} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner12} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner13} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner14} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner15} alt="Metodo de pago" />
            </div>
          </div>

          <footer>
            <div className="redesSociales">
              <img className="logoFooter" src={LogoBlanco} alt="Logo" />
              <div className="logosSociales">
                <a
                  href="https://www.facebook.com/Orotrader/?ref=page_internalhttps://www.facebook.com/Orotrader/?ref=page_internal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconoFB} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/orotrader_1/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconoIG} alt="Instagram" />
                </a>
                <a
                  href="https://twitter.com/Orotrader1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconoTW} alt="Twitter" />
                </a>
              </div>
            </div>
            <div className="enlacesPrivacidad">
              <div className="cuentasFooter">
                <span>{this.state.boot.footer.titulo1}</span>
                <ul>
                  <li>
                    <a
                      href="https://www.orotrader.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link2}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="acercaFooter">
                <span>{this.state.boot.footer.titulo2}</span>
                <ul>
                  <li>
                    <a
                      href="https://www.orotrader.com/Acerca"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link3}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="plataformaFooter">
                <b>
                  <span>{this.state.boot.footer.titulo3}</span>
                </b>
                <ul>
                  <li>
                    <a
                      href="https://download.mql5.com/cdn/web/13141/mt4/venturagroup4setup.exe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Metatrader 4
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://platform.orotrader.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Webtrader
                    </a>
                  </li>
                </ul>
              </div>
              <div className="legalesFooter">
                <span>{this.state.boot.footer.titulo6}</span>
                <ul>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link5}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link6}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link7}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link8}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.boot.footer.link9}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AML
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.orotrader.com/Politicas?option=6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      KYC
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}
