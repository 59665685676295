import React, { Component } from "react";
import { getCookie, getBoot, getApiBase } from "../../../utils";
import Menu from "../../../components/menu/menu.jsx";

import Loading from "../../../assets/images/Loading.gif";
import Griddle, {
  RowDefinition,
  ColumnDefinition,
  plugins,
} from "griddle-react";
import "./tickets.scss";

const styleConfig = {
  classNames: {
    Table: "tablaGriddle",
  },
  styles: {
    Filter: { fontSize: 18, float: "right", marginBottom: "10px" },
    Table: {
      width: "100%",
      textAlign: "Center",
    },
    TableHeading: { color: "white", background: "#C4C4C4" },
    TableHeadingCell: { padding: "14px 0" },
    Cell: { padding: "14px 0" },
    Pagination: { float: "right", marginBottom: "10px" },
  },
};
const layoutTablas = ({ Table, Pagination, Filter }) => (
  <div className="tabla">
    <Filter />
    <Table />
    <Pagination />
  </div>
);
const CustomColumnCenter = ({ value }) => (
  <span className="columnaCentrada"> {value}</span>
);
export default class Tickets extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
    session_id: 113,
    ticketsAbiertos: [],
    ticketsCerrados: [],
  };
  componentDidMount() {
    this.getTickets();
  }

  getTickets = () => {
    let data = {
      session_id: getCookie("session_id"),
    };
    fetch(getApiBase() + "/get/CustomerData?selection=tickets", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        // console.log(response);
        if (response.result === 1) {
          this.setState({
            ticketsAbiertos: response.data.tickets.open,
            ticketsCerrados: response.data.tickets.closed,
            loading: false,
          });
        } else {
          console.log("Fallo peticion");
        }
      });
  };
  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="9" />
            <div className="principal tickets">
              {!this.state.loading && (
                <>
                  <h1>Tickets</h1>
                  <h1>{this.state.boot.tickets.abiertos}</h1>
                  <div className="ticketsAbiertos">
                    <Griddle
                      pageProperties={{
                        pageSize: 10,
                      }}
                      data={this.state.ticketsAbiertos}
                      plugins={[plugins.LocalPlugin]}
                      components={{
                        Layout: layoutTablas,
                      }}
                      styleConfig={styleConfig}
                    >
                      <RowDefinition>
                        <ColumnDefinition
                          id="ticket_no"
                          title={this.state.boot.tickets.tabla_columna1}
                          customComponent={CustomColumnCenter}
                          width="16%"
                        />
                        <ColumnDefinition
                          id="tp_id"
                          title={this.state.boot.tickets.tabla_columna2}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="type"
                          title={this.state.boot.tickets.tabla_columna3}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="amount_request"
                          title={this.state.boot.tickets.tabla_columna4}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="currency"
                          title={this.state.boot.tickets.tabla_columna5}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="status"
                          title={this.state.boot.tickets.tabla_columna6}
                          customComponent={CustomColumnCenter}
                        />
                      </RowDefinition>
                    </Griddle>
                  </div>
                  <h1>{this.state.boot.tickets.cerrados}</h1>
                  <div className="ticketsAbiertos">
                    <Griddle
                      pageProperties={{
                        pageSize: 10,
                      }}
                      data={this.state.ticketsCerrados}
                      plugins={[plugins.LocalPlugin]}
                      components={{
                        Layout: layoutTablas,
                      }}
                      styleConfig={styleConfig}
                    >
                      <RowDefinition>
                        <ColumnDefinition
                          id="ticket_no"
                          title={this.state.boot.tickets.tabla_columna1}
                          customComponent={CustomColumnCenter}
                          width="16%"
                        />
                        <ColumnDefinition
                          id="tp_id"
                          title={this.state.boot.tickets.tabla_columna2}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="type"
                          title={this.state.boot.tickets.tabla_columna3}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="amount_request"
                          title={this.state.boot.tickets.tabla_columna4}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="currency"
                          title={this.state.boot.tickets.tabla_columna5}
                          customComponent={CustomColumnCenter}
                        />
                        <ColumnDefinition
                          id="status"
                          title={this.state.boot.tickets.tabla_columna6}
                          customComponent={CustomColumnCenter}
                        />
                      </RowDefinition>
                    </Griddle>
                  </div>
                </>
              )}
              {this.state.loading && (
                <img className="loading" alt="Loading" src={Loading} />
              )}
            </div>
          </section>
        </div>
      </>
    );
  }
}
