import React, { Component } from "react";
import swal from "sweetalert";
import { getCookie, getBoot, getApiBase } from "../../../utils";
import Menu from "../../../components/menu/menu.jsx";
import IconoOjo from "../../../assets/images/Iconos/Ojo.png";
import "./contrasena.scss";

export default class Contrasena extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
  };

  showPass = (id) => {
    let x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  updatePassword = (e) => {
    e.preventDefault();
    swal({
      title: this.state.boot.swal_warning_title,
      text: this.state.boot.swal_warning_text2,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((willUpdate) => {
      if (willUpdate) {
        let data = {
          session_id: getCookie("session_id"),
          oldpass: document.getElementById("oldPass").value,
          newpass: document.getElementById("newPass").value,
          confirmnewpass: document.getElementById("repeatPass").value,
        };
        // console.log(data);
        fetch(getApiBase() + "/update/Pass", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.result === 1) {
              swal({
                title: this.state.boot.swal_success_title,
                text: this.state.boot.swal_success_text4,
                icon: "success",
              });
              document.getElementById("update-pass-form").reset();
            } else {
              swal({
                title: "Oops",
                text: this.state.boot[data.error],
                icon: "error",
              });
            }
          });
      }
    });
  };
  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="5" />
            <div className="principal">
              <div className="contenedorContrasena">
                <h1>{this.state.boot.contrasena.titulo}</h1>
                <p>{this.state.boot.contrasena.parrafo}</p>
                <form
                  id="update-pass-form"
                  onSubmit={(e) => this.updatePassword(e)}
                >
                  <div className="contrasena">
                    <label>{this.state.boot.contrasena.texto1}</label>
                    <div className="contrasenaVer">
                      <input type="password" id="oldPass" required />
                      <img
                        onClick={(e) => this.showPass("oldPass")}
                        src={IconoOjo}
                        alt="Ver"
                      />
                    </div>
                  </div>
                  <div className="contrasena">
                    <label>{this.state.boot.contrasena.texto2}</label>
                    <div className="contrasenaVer">
                      <input
                        type="password"
                        id="newPass"
                        pattern="^(?=.*[a-zA-Z])(?=\w*[0-9])[^_\W]{6,12}$"
                        title={this.state.boot.perfil.label_contrasena}
                        required
                      />
                      <img
                        onClick={(e) => this.showPass("newPass")}
                        src={IconoOjo}
                        alt="Ver"
                      />
                    </div>
                  </div>
                  <div className="contrasena">
                    <label>{this.state.boot.contrasena.texto3}</label>
                    <div className="contrasenaVer">
                      <input type="password" id="repeatPass" required />
                      <img
                        onClick={(e) => this.showPass("repeatPass")}
                        src={IconoOjo}
                        alt="Ver"
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    className="boton"
                    value={this.state.boot.contrasena.texto4}
                  />
                </form>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
