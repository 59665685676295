import React, { Component } from "react";
import { getCookie, getBoot, getApiBase, eraseCookie } from "../../../utils";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Menu from "../../../components/menu/menu.jsx";
import "./depositar.scss";
import swal from "sweetalert";
// import Tarjetas from "../../../components/tarjetas/tarjetas.jsx";
import Tarjetas1 from "../../../components/tarjetas/tarjetas1.jsx";
import Efectivo1 from "../../../components/efectivo/efectivo1.jsx";
// import Efectivo from "../../../components/efectivo/efectivo.jsx";
import Loading from "../../../assets/images/Loading.gif";

export default class Depositar extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
    loadingModal: false,
    modalTarjeta: false,
    deposits_meths: {
      cards: []
    },
    paymentsMethods: {},
    country: getCookie("country")
  };

  componentDidMount() {
    this.getCards();
    this.getPaymentMethods()
  }
  getPaymentMethods = async () => {
    try {
      this.setState({
        loading: true
      });
      const request = await fetch("https://client.orotrader.com/api/configuration.php", {
        method: "POST",
        body: JSON.stringify({ tp_id: JSON.parse(getCookie("tp_accounts"))[0].tp_id }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const data = await request.json();
      this.setState({
        paymentsMethods: await data,
        loading: false
      });
    } catch (error) {
      console.error(error);
    }
  }
  getCards = () => {
    let data = {
      session_id: getCookie("session_id"),
    };
    fetch(`${getApiBase()}/get/CustomerData?selection=deposits_meth`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          deposits_meths: response.data.deposits_meth,
          loading: false,
        });
      });
  };
  registrarTarjeta(e) {
    const that = this;
    e.preventDefault();
    let nombre = document.getElementById("nombreTitular").value;
    let tarjeta = document.getElementById("numeroTarjeta").value;
    let expiracion = document.getElementById("fechaExp").value;
    let cvv = document.getElementById("cvc").value;
    swal({
      title: this.state.boot.swal_warning_title,
      text: this.state.boot.swal_warning_text4,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirm) => {
      if (confirm) {
        let data = {
          cvv: cvv,
          holder: nombre,
          number: tarjeta,
          expires: expiracion,
        };
        fetch(getApiBase() + "/cards/Management", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            action: "insert",
            session_id: getCookie("session_id"),
            data: data,
          }),
        })
          .then((r) => r.json())
          .then((res) => {
            if (res.result === 1) {
              swal({
                icon: "success",
                title: this.state.boot.swal_success_title,
                text: this.state.boot.swal_success_text5,
              }).then(function r() {
                let dep_meths = that.state.deposits_meths;
                dep_meths.cards.push(res.data);
                that.setState({
                  modalTarjeta: false,
                  deposits_meths: dep_meths,
                });
              });
            } else {
              if (res.error === "EISN") {
                eraseCookie();
              } else {
                swal({
                  icon: "error",
                  title: "Oops!",
                  text: that.state.boot[res.error],
                });
              }
            }
          });
      }
    });
  }
  pagoTarjeta = async (e) => {
    e.preventDefault();
    this.setState({
      loadingModal: true
    });
    try {
      const response = await fetch(`${getApiBase()}/transaction`, {
        method: "POST",
        body: JSON.stringify({
          amount: e.target[0].value,
          idCard: e.target[1].value,
          firstName: e.target[2].value,
          lastName: e.target[3].value,
          email: e.target[4].value,
          session_id: getCookie("session_id")
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const {result, error} = await response.json();
      if (result) {
        swal({
          title: "Exitoso!",
          text: "Tu pago fue realizado con éxito",
          icon: "success"
        });
        this.setState({ modalTarjetaPago: false });
      } else {
        swal({
          title: "Ops!",
          text: `¡Algo pasó! ${error}`,
          icon: "error"
        });
      }
      this.setState({
        loadingModal: false
      });
    } catch(err) {
      swal({
        title: "Ops!",
        text: `¡Algo pasó! ${err.message}`,
        icon: "error"
      });
      this.setState({
        loadingModal: false
      });
    }
  }
  formatExp = (e) => {
    e.preventDefault();
    let code = e.keyCode;
    let input = document.getElementById("fechaExp").value;
    let input_len = input.length;
    let string_r = "";
    if (input_len < 2) {
      string_r = input;
    } else if (input_len === 2 && code !== 8) {
      string_r = input + "/";
    } else if (input_len > 2 && code === 8) {
      string_r = input;
    } else {
      string_r = input;
    }
    document.getElementById("fechaExp").value = string_r;
  };

  renderTps = () => {
    let tps = JSON.parse(getCookie("tp_accounts"));
    let cuenta = [];
    tps.forEach((element) => {
      let sel = element.tp_id === this.state.selected_tp ? "true" : "false";
      cuenta.push(
        <option
          key={element.tp_id}
          defaultValue={{ sel }}
          value={element.tp_id}
        >
          {element.tp_id}
        </option>
      );
    });
    return cuenta;
  };

  render() {
    const minAmountUser = parseInt(getCookie("minAmount"));
    const minAmount = this.state.paymentsMethods?.PayCips?.minAmount;
    const maxAmount = this.state.paymentsMethods?.PayCips?.maxAmount;
    const validateMinAmount = minAmount > minAmountUser;
    const minAmountToUse = validateMinAmount ? minAmount : minAmountUser;
    
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="6" />
            <div className="principal depositarGeneral">
              {!this.state.loading ? (
                <>
                  <h1>{this.state.boot.depositar.texto1}</h1>
                  <div className="contenedorDepositos">
                    <div className="cuentaDepositos">
                      <p>{this.state.boot.depositar.texto2}</p>
                      <div className="selectorDepositos">
                        <span>{this.state.boot.depositar.texto3}</span>
                        <select name="numeroCuenta" id="numeroCuenta">
                          {this.renderTps()}
                        </select>
                      </div>
                    </div>

                    <div className="tarjetasDepositos">
                      <div className="tituloDepositos">
                        <h1>{this.state.boot.depositar.texto4_1}</h1>
                        <button
                          onClick={(e) => this.setState({ modalTarjeta: true })}
                          className="boton botonAmarillo"
                        >
                          {this.state.boot.depositar.texto5}
                        </button>
                      </div>
                      <div className="contenedorSlider">
                        <Tarjetas1
                          txt={this.state.boot.depositar}
                          data={this.state.deposits_meths}
                        />
                      </div>
                    </div>
                    {this.state.paymentsMethods?.PayCips && (
                      <div className="Pagotarjeta">
                        <button
                          onClick={(e) => this.setState({ modalTarjetaPago: true })}
                          className="boton"
                        >
                          {"Pagar con tarjeta"}
                        </button>
                      </div>
                    )}
                    {this.state.paymentsMethods?.Payretailers && (
                      <div className="efectivoDepositos">
                        <p className="efectivoDepositos__legend">{"O paga en efectivo"}</p>
                        <Efectivo1 data={this.state.paymentsMethods} minAmountUser={minAmountUser} />
                      </div>
                    )}
                  </div>
                </>
              ) : <img className="loading" alt="Loading" src={Loading} />}
            </div>
          </section>
        </div>
        <Modal isOpen={this.state.modalTarjeta}>
          <ModalHeader>
            <h1> {this.state.boot.depositar.texto5}</h1>
          </ModalHeader>
          <ModalBody>
            <form
              className="formularioTarjeta"
              onSubmit={(e) => this.registrarTarjeta(e)}
            >
              <label htmlFor="nombreTarjeta">
                {this.state.boot.depositar.texto7_1}
              </label>
              <input
                id="nombreTitular"
                type="text"
                pattern="^((?:[A-Za-z]+ ?){2,3})$"
                required
              />
              <label htmlFor="numeroTarjeta">
                {this.state.boot.depositar.texto8}
              </label>
              <input
                id="numeroTarjeta"
                type="text"
                minLength="13"
                maxLength="16"
                required
              />
              <label htmlFor="fechaExp">MM/AA</label>
              <input
                id="fechaExp"
                type="text"
                placeholder="00/00"
                maxLength="5"
                onKeyUp={(e) => this.formatExp(e)}
                required
              />
              <label htmlFor="cvc">CVV</label>
              <input
                id="cvc"
                type="text"
                placeholder="123"
                maxLength="4"
                required
              />

              <button
                onClick={(e) => this.setState({ modalTarjeta: false })}
                className="boton botonAmarillo"
              >
                {this.state.boot.perfil.cancelar}
              </button>
              <button className="boton botonAmarillo" type="submit">
                {this.state.boot.depositar.texto7_2}
              </button>
            </form>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.modalTarjetaPago}>
          <ModalHeader>
            <p> {"Pagar con tarjeta"}</p>
          </ModalHeader>
          <ModalBody>
            {this.state.loadingModal && (
              <div className="loader-modal">
                <img src={Loading} alt="" />
              </div>
            )}
            {this.state.deposits_meths.cards.length === 0 ? (
              <div className="formularioTarjeta">
                <p>Debes contar con al menos una tarjeta</p>
                <button
                  onClick={(e) => this.setState({ modalTarjetaPago: false })}
                  type="button"
                  className="boton botonAmarillo"
                  disabled={this.state.loadingModal}
                >
                  {"Cerrar"}
                </button>
              </div>
            ) : (
              <form
                className="formularioTarjeta"
                onSubmit={(e) => this.pagoTarjeta(e)}
              >
                <label htmlFor="paymentAmount" data-currency="usd">
                  {"Monto"}
                  <input
                    id="paymentAmount"
                    type="number"
                    placeholder={`min: ${minAmountToUse}`}
                    min={minAmountToUse}
                    max={maxAmount}
                    step="0.01"
                    required
                  />
                </label>
                <label htmlFor="paymentCard">
                  {"Número de la tarjeta"}
                </label>
                <select
                  id="paymentCard"
                  required
                >
                  <option value="" hidden>Seleccione</option>
                  {this.state.deposits_meths.cards.map((card) => (
                    <option key={card.idCrmCard} value={card.idCrmCard}>{card.card_number}</option>
                  ))}
                </select>
                <label htmlFor="paymentName">
                  {"Nombre"}
                </label>
                <input
                  id="paymentName"
                  type="text"
                  pattern="^((?:[A-Za-z]+ ?){2,3})$"
                  required
                />
                <label htmlFor="PaymentLastName">
                  {"Apellido"}
                </label>
                <input
                  id="PaymentLastName"
                  type="text"
                  pattern="^((?:[A-Za-z]+ ?){2,3})$"
                  required
                />
                <label htmlFor="PaymentEmail">
                  {"Email"}
                </label>
                <input
                  id="PaymentEmail"
                  type="email"
                  // pattern="^((?:[A-Za-z]+ ?){2,3})$"
                  required
                />
                <button
                  onClick={(e) => this.setState({ modalTarjetaPago: false })}
                  type="button"
                  className="boton botonAmarillo"
                  disabled={this.state.loadingModal}
                >
                  {this.state.boot.perfil.cancelar}
                </button>
                <button
                  className="boton botonAmarillo"
                  type="submit"
                  disabled={this.state.loadingModal}
                >
                  {"Pagar"}
                </button>
              </form>
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }
}
