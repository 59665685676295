import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCookie, setCookie, getBoot, eraseCookie } from "../../utils";
import swal from "sweetalert";
import "./header.scss";
import Logo from "../../assets/images/LogoBlanco.png";
import IconoLenguaje from "../../assets/images/Iconos/Lenguaje.svg";
import IconoUsuario from "../../assets/images/Iconos/Usuario.png";

export default class Header extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    nivel: "1",
    user: "Usuario",
    imagenPerfil: IconoUsuario,
  };
  componentDidMount() {
    this.setState({ user: getCookie("name") + " " + getCookie("lastname") });
    if (
      getCookie("imagenPerfil") !== null &&
      getCookie("imagenPerfil") !== ""
    ) {
      this.setState({ imagenPerfil: getCookie("imagenPerfil") });
    }
  }
  showLangMenu = () => {
    let x = document.getElementById("selectorLenguaje");
    if (x.style.display === "none" || x.style.display === "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  changeLang = (lang) => {
    setCookie("OroClientlang", lang, 20);
    window.location.reload();
  };
  logoutConfirm() {
    swal({
      title: this.state.boot.swal_warning_title,
      text: this.state.boot.swal_warning_text3,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        eraseCookie();
      }
    });
  }

  render() {
    return (
      <>
        <div className="contenedor">
          <div className="titular">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
            <div className="contenedorUsuario">
              <div className="ajustesLenguaje">
                <button
                  className="botonMostrarLenguaje"
                  onClick={(e) => this.showLangMenu()}
                >
                  <img
                    src={IconoLenguaje}
                    alt="Icono Lenguaje"
                    className="iconoLenguaje"
                  />
                </button>
                <div className="selectorLenguaje" id="selectorLenguaje">
                  <button
                    className={
                      getCookie("OroClientlang") === "es" ? "botonActivo" : ""
                    }
                    onClick={(e) => this.changeLang("es")}
                    id="idiomaEspanol"
                  >
                    Español
                  </button>
                  <button
                    className={
                      getCookie("OroClientlang") === "en" ? "botonActivo" : ""
                    }
                    onClick={(e) => this.changeLang("en")}
                    id="idiomaIngles"
                  >
                    English
                  </button>
                  <button
                    className={
                      getCookie("OroClientlang") === "pt" ? "botonActivo" : ""
                    }
                    onClick={(e) => this.changeLang("pt")}
                    id="idiomaPortugues"
                  >
                    Português
                  </button>
                </div>
              </div>
              <div className="datos">
                <div className="nivel">
                  <span>
                    {this.state.boot.titular.nivel} {this.state.nivel}
                  </span>
                </div>
                <div className="nombre">
                  <span id="navUserName">{this.state.user}</span>
                </div>
                <div className="perfil">
                  <Link to="/detalles">
                    <span>{this.state.boot.titular.perfil}</span>
                  </Link>
                </div>
                <button
                  className="cerrar"
                  onClick={(e) => this.logoutConfirm()}
                >
                  <span>{this.state.boot.titular.sesion}</span>
                </button>
              </div>
              <div className="imagen">
                <img
                  id="imagenPerfil"
                  src={this.state.imagenPerfil}
                  alt="Imagen de usuario"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
