import React, { Component } from "react";
import swal from "sweetalert";
import { getCookie, getBoot } from "../../../utils";
import Menu from "../../../components/menu/menu.jsx";
import "./contactanos.scss";

export default class Contactanos extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
  };
  sendEmail(e) {
    e.preventDefault();
    swal({
      title: this.state.boot.swal_warning_title,
      text: this.state.boot.swal_success_text10,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        let data = {
          nombre: document.getElementById("nombre").value,
          email: document.getElementById("correo").value,
          mensaje: document.getElementById("mensaje").value,
          subject: document.getElementById("asunto").value,
          action: "sendContactMail",
        };
        fetch("https://www.orotrader.com/api/sendEmail.php", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.result === 1) {
              swal({
                title: this.state.boot.swal_success_title,
                text: this.state.boot.swal_success_text11,
                icon: "success",
              });
              document.getElementById("contact-form").reset();
            } else {
              swal({
                title: "Oops!",
                text: this.state.boot.swal_error_text2,
                icon: "error",
              });
            }
          });
      }
    });
  }
  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="10" />
            <div className="principal">
              <div className="contenedorContacto">
                <p>{this.state.boot.contactanos.texto1}</p>
                <span>{this.state.boot.contactanos.texto2}</span>
                <form id="contact-form" onSubmit={(e) => this.sendEmail(e)}>
                  <label htmlFor="nombre">
                    {this.state.boot.contactanos.texto3}
                  </label>
                  <input type="text" id="nombre" />
                  <label htmlFor="correo">
                    {this.state.boot.contactanos.texto4}
                  </label>
                  <input type="text" id="correo" />
                  <label htmlFor="asunto">
                    {this.state.boot.contactanos.texto5}
                  </label>
                  <input type="text" id="asunto" />
                  <label htmlFor="mensaje">
                    {this.state.boot.contactanos.texto6}
                  </label>
                  <textarea
                    name="mensaje"
                    id="mensaje"
                    cols="30"
                    rows="10"
                  ></textarea>
                  <input
                    type="submit"
                    value={this.state.boot.contactanos.texto7}
                  />
                </form>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
