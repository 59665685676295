import React, { Component } from "react";
import swal from "sweetalert";
import { getCookie, getBoot, getApiBase, eraseCookie } from "../../../utils";
import Menu from "../../../components/menu/menu.jsx";
import "./extraer.scss";

export default class Extraer extends Component {
  state = {
    boot: getBoot()[getCookie("OroClientlang")],
    loading: true,
    can: false,
    maxwithdrawal: [],
    pendingwithdrawal: 0,
    total_deposits: 0,
  };
  componentDidMount = () => {
    this.getInfoCustumer();
  }
  renderTps = () => {
    let tps = JSON.parse(getCookie("tp_accounts"));
    let cuenta = [];
    tps.forEach((element) => {
      let sel = element.tp_id === this.state.selected_tp ? "true" : "false";
      cuenta.push(
        <option selected={sel} value={element.tp_id}>
          {element.tp_id}
        </option>
      );
    });
    return cuenta;
  };
  getInfoCustumer = () => {
    let data = {
      session_id: getCookie("session_id"),
    };
    fetch(getApiBase() + "/get/CustomerData?selection=summary,tp_accounts", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.result === 1) {
          // console.log(response.data);
          this.setState({
            total_deposits: response.data.summary.total_deposits,
            pendingwithdrawal: response.data.summary.total_pending_withdrawal,
          });
          if (response.data.summary.total_deposits > 0) {
            this.setState({ can: true });
          } else {
            this.setState({ can: false });
          }
          if (response.data.tp_accounts !== undefined) {
            let max = this.state.maxwithdrawal;
            for (let i = 0; i < response.data.tp_accounts.lenght; i++) {
              max.push({
                tp: response.data.tp_accounts[i].login,
                max: response.data.tp_accounts[i].margin_free,
              });
            }
            this.setState({ maxwithdrawal: max });
          }
          // console.log(this.state);
        }
      });
  };

  requestWithdrawal = (e) => {
    e.preventDefault();
    if (this.state.can) {
      let data = {
        currency: document.getElementById("moneda").value,
        amount: document.getElementById("monto").value,
        tp_id: document.getElementById("tpCuenta").value,
        observations: document.getElementById("mensaje").value,
      };
      if (
        this.state.total_deposits >
        this.state.pendingwithdrawal + Number(data.amount)
      ) {
        swal({
          title: this.state.boot.swal_warning_title,
          text:
            this.state.boot.swal_warning_text8 +
            data.amount +
            " " +
            data.currency +
            this.state.boot.swal_warning_text9 +
            data.tp_id,
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then((confirm) => {
          if (confirm) {
            fetch(getApiBase() + "/insert/Transaction", {
              method: "POST",
              body: JSON.stringify({
                session_id: getCookie("session_id"),
                type: "Withdrawal",
                data: data,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((res) => res.json())
              .then((response) => {
                if (response.result === 1) {
                  swal({
                    icon: "success",
                    title: this.state.boot.swal_success_title,
                    text: this.state.boot.swal_success_text7,
                  });
                  let data = {};
                  data["email"] = getCookie("email");
                  data["action"] = "sendWithdrawalMail";
                  fetch("https://www.orotrader.com/api/sendEmail.php", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                    .then((res) => res.json())
                    .then((response) => {});
                } else {
                  if (response.error === "EISN") {
                    eraseCookie();
                  } else {
                    swal({
                      icon: "error",
                      title: "Oops!",
                      text: this.state.boot[response.error],
                    });
                  }
                }
              });
          }
        });
      } else {
        swal({
          title: this.state.boot.swal_warning_text10,
          text: this.state.boot.swal_warning_text11,
          icon: "warning",
        });
      }
    } else {
      swal({
        title: this.state.boot.swal_warning_text10,
        text: this.state.boot.swal_warning_text11,
        icon: "warning",
      });
    }
  };
  render() {
    return (
      <>
        <div className="contenedor">
          <section className="mallaGeneral">
            <Menu page="7" />
            <div className="principal extraer">
              <h1>{this.state.boot.extraer.texto1}</h1>
              <form onSubmit={(e) => this.requestWithdrawal(e)}>
                <div className="contenedorExtraer">
                  <p>{this.state.boot.extraer.texto2}</p>
                  <div className="contenedorOpciones">
                    <div className="selectorOpciones">
                      <span>{this.state.boot.extraer.texto3}</span>
                      <select name="" id="tpCuenta">
                        {this.renderTps()}
                      </select>
                    </div>
                    <div className="monedaOpciones">
                      <label htmlFor="moneda">
                        {this.state.boot.extraer.texto4_1}
                      </label>
                      <select name="moneda" id="moneda">
                        <option value="USD">USD</option>
                      </select>
                    </div>
                    <div className="montoOpciones">
                      <label htmlFor="monto">
                        {this.state.boot.extraer.texto4_2}
                      </label>
                      <input id="monto" type="number" min="250" step="0.01" />
                    </div>
                  </div>
                </div>
                <p>{this.state.boot.extraer.texto5}</p>
                <textarea name="mensaje" id="mensaje" rows="10"></textarea>
                <input
                  className="boton botonAmarillo"
                  type="submit"
                  value={this.state.boot.extraer.texto6}
                />
              </form>
            </div>
          </section>
        </div>
      </>
    );
  }
}
